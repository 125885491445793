import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Input } from "@mui/material";
import NumberFormat from "react-number-format";
import { TimesheetStatus } from "../../commons/Enums";
import usePermissions from "../../hooks/usePermissions";
import { PropTypes } from "prop-types";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useFeatureFlags } from "../../commons/Features";
import { useSelector } from "react-redux";
import DealsheetValueIcon from "./DealsheetValueIcon";
import useTimesheetAdjustments from "hooks/useTimesheetAdjustments";
import TimecardRowInformation from "components/shared/components/TimecardRowInformation";
import { IconButton } from "@mui/material";

const TimeCardHoursTableRow = (props) => {
  const {
    row,
    status,
    onTimecardEntriesChange,
    onTimecardAdjustmentEntriesChange,
    onAddAdjustment,
    onRemoveAdjustment,
    types,
    adjustments,
    isAdjustment,
    missedLunchHours,
    isDigitalTimecardEntry,
    isExceptionState,
  } = props;
  const {
    canSeeTotalInTimecardHours,
    canEditRateInTimecardHours,
    canAdjustCurrentTimecard,
    canCreateTimecardAjustment,
    canEditDtHours,
  } = usePermissions();
  const { isAdjustmentsOn } = useFeatureFlags();
  const timesheetSelected = useSelector((state) => state.missedShiftCalculation.timesheetSelected);
  const {
    canAddAdjustmentLine,
    canRemoveAdjustmentLine,
    styleVisibilityAddButton,
    updateVisibilityAddButton,
  } = useTimesheetAdjustments(row, isAdjustment, adjustments);

  const isRateEditable = () => {
    const isAdjustmentTimecard =
      isAdjustmentsOn && timesheetSelected && timesheetSelected.isAdjustment;
    const isAdjustmentRow = isAdjustmentsOn && isAdjustment;
    const isApprovedRegularTimecard = status === TimesheetStatus.APPROVED && !isAdjustmentTimecard;

    return (
      !row.isLocked &&
      ((row.isRateEditable && !isAdjustmentTimecard) ||
        (isApprovedRegularTimecard && canEditRateInTimecardHours) ||
        (isAdjustmentRow && canAdjustCurrentTimecard) ||
        (canCreateTimecardAjustment && isAdjustmentTimecard))
    );
  };

  const isHoursEditable = () => {
    const isAdjustmentTimecard =
      isAdjustmentsOn && timesheetSelected && timesheetSelected.isAdjustment;

    const isAdjustmentRow = isAdjustmentsOn && isAdjustment;

    return (
      !row.isLocked &&
      ((!row.isHoursLocked && !isAdjustmentTimecard) ||
        (isAdjustmentRow && canAdjustCurrentTimecard) ||
        (canCreateTimecardAjustment && isAdjustmentTimecard) ||
        (isDigitalTimecardEntry && canEditDtHours && row.name !== "FlatIncentiveBonus"))
    );
  };

  const handleRateChange = (name, value) => {
    const newEntries = types.map((r) => {
      return r.name === name ? { ...r, rate: value } : r;
    });
    onTimecardEntriesChange(newEntries);
  };

  const handleHoursChange = (name, value, dtHoursChanged) => {
    const newEntries = types.map((r) => {
      return r.name === name ? { ...r, hours: value } : r;
    });
    onTimecardEntriesChange(newEntries, dtHoursChanged);
  };

  const addAdjustment = (name) => {
    onAddAdjustment(name);
  };

  const removeAdjustment = (rowData) => {
    onRemoveAdjustment(rowData.name, rowData.order);
  };

  const handleAdjustmentHoursChange = (name, order, value) => {
    const newEntries = adjustments.map((r) => {
      return r.name === name && r.order === order ? { ...r, hours: value } : r;
    });
    onTimecardAdjustmentEntriesChange(newEntries);
  };

  const handleAdjustmentRateChange = (name, order, value) => {
    const newEntries = adjustments.map((r) => {
      return r.name === name && r.order === order ? { ...r, rate: value } : r;
    });
    onTimecardAdjustmentEntriesChange(newEntries);
  };

  const shouldDisplayOriginalHour = (row) => {
    return (
      isDigitalTimecardEntry &&
      (row.name === "RegularHours" ||
        row.name === "OnCall" ||
        row.name === "CallBack" ||
        row.name === "Overtime8" ||
        row.name === "Overtime40" ||
        row.name === "DoubleTime" ||
        row.name === "Holiday" ||
        row.name === "PreceptCharge") &&
      row.originalHours !== null &&
      row?.originalHours !== row.hours
    );
  };

  const shouldDisplayDTHours = shouldDisplayOriginalHour(row);

  const totalHoursCalculated = row.hours * row.rate;

  const shouldHighlight = (row) => {
    if (row.hours === 0) return false;

    switch (row.name) {
      case "FlatIncentiveBonus":
      case "IncentiveBonus":
        return row.rate !== 0;
      case "Holiday":
        return isExceptionState && status !== TimesheetStatus.BILLING;
    }

    return false;
  };

  return (
    <TableRow
      className={shouldHighlight(row) ? "timecard-expense-highlight" : ""}
      onMouseEnter={() =>
        isAdjustmentsOn && canAdjustCurrentTimecard ? updateVisibilityAddButton(true) : undefined
      }
      onMouseLeave={() =>
        isAdjustmentsOn && canAdjustCurrentTimecard ? updateVisibilityAddButton(false) : undefined
      }>
      <TableCell
        component="th"
        scope="row"
        style={{ paddingLeft: isAdjustment ? "20px" : "", minWidth: "215px" }}>
        {canAddAdjustmentLine && (
          <IconButton
            sx={styleVisibilityAddButton}
            size="small"
            color="primary"
            onClick={() => addAdjustment(row.name)}>
            <AddIcon fontSize="small" />
          </IconButton>
        )}
        {canRemoveAdjustmentLine && (
          <IconButton size="small" color="primary" onClick={() => removeAdjustment(row)}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        {row.title}
        {missedLunchHours > 0 && row.name == "RegularHours" && (
          <TimecardRowInformation
            informationText="Missed Lunch Hours:"
            informationValue={`${missedLunchHours}`}
          />
        )}

        {shouldDisplayDTHours && (
          <TimecardRowInformation
            informationText="Digital Timecard original hours:"
            informationValue={row?.originalHours}
          />
        )}
      </TableCell>
      <TableCell align="right">
        {isRateEditable() ? (
          <NumberFormat
            allowNegative={false}
            customInput={Input}
            fixedDecimalScale={2}
            decimalScale={2}
            value={row.rate}
            onValueChange={(value) => {
              isAdjustmentsOn && isAdjustment
                ? handleAdjustmentRateChange(row.name, row.order, value.floatValue)
                : handleRateChange(row.name, value.floatValue);
            }}
            thousandSeparator={true}
            prefix={"$"}
            onClick={(e) => {
              e.target.select();
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                isAdjustmentsOn && isAdjustment
                  ? handleAdjustmentRateChange(row.name, row.order, 0)
                  : handleRateChange(row.name, 0);
              }
            }}
            defaultValue={0}
            allowEmptyFormatting={false}
            style={{ width: "50%" }}
          />
        ) : (
          <NumberFormat
            fixedDecimalScale={2}
            decimalScale={2}
            value={row.rate}
            prefix={"$"}
            thousandSeparator={true}
            displayType={"text"}
            style={{ width: "50%" }}
          />
        )}
        <DealsheetValueIcon fieldName={row.name} arrayName="timecardDealsheetTypes" />
      </TableCell>
      <TableCell align="right">
        {isHoursEditable() ? (
          <NumberFormat
            customInput={Input}
            fixedDecimalScale={2}
            decimalScale={2}
            value={row.hours}
            onValueChange={(value) => {
              isAdjustmentsOn && isAdjustment && canAdjustCurrentTimecard
                ? handleAdjustmentHoursChange(row.name, row.order, value.floatValue)
                : handleHoursChange(row.name, value.floatValue);
            }}
            thousandSeparator={true}
            onBlur={(e) => {
              if (e.target.value === "") {
                isAdjustmentsOn && isAdjustment && canAdjustCurrentTimecard
                  ? handleAdjustmentHoursChange(row.name, row.order, 0)
                  : handleHoursChange(row.name, 0);
              }
            }}
            onClick={(e) => {
              e.target.select();
            }}
            style={{ width: "50%" }}
          />
        ) : (
          <NumberFormat
            customInput={Input}
            fixedDecimalScale={2}
            decimalScale={2}
            value={row.hours}
            thousandSeparator={true}
            displayType={"text"}
            style={{ width: "50%" }}
          />
        )}
      </TableCell>
      {canSeeTotalInTimecardHours && (
        <>
          <TableCell align="right">
            <NumberFormat
              fixedDecimalScale={2}
              decimalScale={2}
              prefix={"$"}
              value={totalHoursCalculated}
              thousandSeparator={true}
              displayType={"text"}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

TimeCardHoursTableRow.propTypes = {
  row: PropTypes.object,
  status: PropTypes.string,
  onTimecardEntriesChange: PropTypes.func,
  onTimecardAdjustmentEntriesChange: PropTypes.func,
  onAddAdjustment: PropTypes.func,
  onRemoveAdjustment: PropTypes.func,
  types: PropTypes.array,
  adjustments: PropTypes.array,
  isAdjustment: PropTypes.bool,
  missedLunchHours: PropTypes.number,
  handleNewHoursOnDTRow: PropTypes.func,
  isDigitalTimecardEntry: PropTypes.bool,
  isExceptionState: PropTypes.bool,
};

export default TimeCardHoursTableRow;
