import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import VmsApi from "../../api/VmsApi";
import { downloadPayrollReport, downloadBillingReport } from "../shared/functions/ReportDownload";
import { lockTimecardsPayrollReport } from "../shared/functions/LockTimecards";
import { PayrollLockType } from "../../commons/Enums";
import MaterialTable from "material-table";
import LockPayrollDialog from "../shared/components/LockPayrollDialog";
import { downloadUltiProDataExport } from "../shared/functions/DownloadDataExport";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import PayrollLocksApi from "../../api/PayrollLocksApi";
import { TITLE_CHANGED } from "../../redux/actions/actionTypes";
import { useDispatch } from "react-redux";

const VmsProfile = () => {
  const snackbar = useSnackbar();

  const [vmsProfiles, setVmsProfiles] = useState(null);
  const [isLockPayrollDialogOpen, setLockPayrollDialogOpen] = React.useState(false);
  const [lateTimecardsReleased, setLateTimecardsReleased] = React.useState(false);
  const [lockType, setlockType] = useState(PayrollLockType.RegularTimecard);
  const dispatch = useDispatch();

  const openPayrollDialog = (lType) => {
    setlockType(lType);
    setLockPayrollDialogOpen(true);
  };

  const closePayrollDialog = () => {
    setLockPayrollDialogOpen(false);
  };

  const noFinalReport = async () => {
    await downloadUltiProDataExport(lockType === PayrollLockType.RegularTimecard ? false : true);
  };

  const lockAndDownloadPayrollRawDataReport = async () => {
    await lockTimecardsPayrollReport(lockType, (msg) => snackbar.enqueueSnackbar(msg));
    await downloadUltiProDataExport(lockType === PayrollLockType.RegularTimecard ? false : true);
  };

  const countSortFactory = (property) => {
    if (lateTimecardsReleased) {
      return (row1, row2) => row1[property].total - row2[property].total;
    }

    return (row1, row2) => row1[property].notLate - row2[property].notLate;
  };

  const countRender = lateTimecardsReleased ? (count) => count.total : (count) => count.notLate;

  const countSearchFactory = (property) => {
    if (lateTimecardsReleased) {
      return (terms, rowData) => !terms || terms == rowData[property].total;
    }

    return (terms, rowData) => !terms || terms == rowData[property].notLate;
  };

  const columns = [
    {
      title: "Name",
      field: "vmsName",
      render: (row) => <Link to={{ pathname: `/vms/${row.vmsId}` }}>{row.vmsName}</Link>,
    },
    {
      title: "Pending",
      field: "pendingCount",
      customSort: countSortFactory("pendingCount"),
      customFilterAndSearch: countSearchFactory("pendingCount"),
      render: (row) => countRender(row.pendingCount),
    },
    {
      title: "Payroll",
      field: "forReviewCount",
      customSort: countSortFactory("forReviewCount"),
      customFilterAndSearch: countSearchFactory("forReviewCount"),
      render: (row) => countRender(row.forReviewCount),
    },
    {
      title: "Billing",
      field: "billingCount",
      customSort: countSortFactory("billingCount"),
      customFilterAndSearch: countSearchFactory("billingCount"),
      render: (row) => countRender(row.billingCount),
    },
    {
      title: "Approved",
      field: "approvedCount",
      customSort: countSortFactory("approvedCount"),
      customFilterAndSearch: countSearchFactory("approvedCount"),
      render: (row) => countRender(row.approvedCount),
    },
  ];

  if (!lateTimecardsReleased) {
    columns.push({
      title: "Late",
      field: "lateCount",
    });
  }

  columns.push({
    title: "Reports",
    render: () => (
      <div className="timecard-tile-fileicons">
        <Button>Payroll Report</Button>
        <CanRenderChildren featureFlagName="isBillingOn">
          <Button>Billing Report</Button>
        </CanRenderChildren>
      </div>
    ),
  });

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "VMS Profile" });
  }, []);

  useEffect(() => {
    VmsApi.getVmsProfiles()
      .then((profilesInfo) => {
        setVmsProfiles(profilesInfo.profiles);
        setLockPayrollDialogOpen(false);

        PayrollLocksApi.GetPayrollLockStatusByType(PayrollLockType.RegularTimecard)
          .then((result) => {
            setLateTimecardsReleased(result.IsLocked);
          })
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <div className="view-container">
      {vmsProfiles == null ? (
        <CircularProgress />
      ) : (
        <>
          <div style={{ marginBottom: "10px" }}>
            <Button
              sx={{
                margin: 1,
              }}
              variant="contained"
              onClick={() => {
                openPayrollDialog(PayrollLockType.RegularTimecard);
              }}>
              UltiPro Export
            </Button>

            <Button
              sx={{
                margin: 1,
              }}
              variant="contained"
              onClick={() => {
                downloadPayrollReport("", false, false, false, false).catch((e) =>
                  console.error(e)
                );
              }}>
              All Payroll
            </Button>

            <Button
              sx={{
                margin: 1,
              }}
              variant="contained"
              onClick={() => {
                downloadPayrollReport("", false, false, false, false).catch((e) =>
                  console.error(e)
                );
              }}>
              Triage Payroll
            </Button>

            <CanRenderChildren featureFlagName="isBillingOn">
              <Button
                sx={{
                  "&.MuiButtonBase-root": {
                    marginRight: 16,
                  },
                }}
                variant="contained"
                onClick={() => {
                  downloadBillingReport("", false).catch((e) => console.error(e));
                }}>
                Billing Report
              </Button>
            </CanRenderChildren>

            <Button
              sx={{
                margin: 1,
              }}
              variant="contained"
              onClick={() => {
                openPayrollDialog(PayrollLockType.LateAndAdjustmentTimecard);
              }}>
              Late UltiPro Export
            </Button>

            <Button
              sx={{
                margin: 1,
              }}
              variant="contained"
              onClick={() => {
                downloadPayrollReport("", true, false, false, false).catch((e) => console.error(e));
              }}>
              Late All Payroll
            </Button>

            <CanRenderChildren featureFlagName="isBillingOn">
              <Button
                sx={{
                  margin: 1,
                }}
                variant="contained"
                onClick={() => {
                  downloadBillingReport("", true).catch((e) => console.error(e));
                }}>
                Late Billing Report
              </Button>
            </CanRenderChildren>
          </div>

          <LockPayrollDialog
            open={isLockPayrollDialogOpen}
            onClose={closePayrollDialog}
            onPayrollReport={lockAndDownloadPayrollRawDataReport}
            onNoFinalReport={noFinalReport}
            lockType={lockType}
          />

          {vmsProfiles != null && (
            <div style={{ display: "grid" }}>
              <MaterialTable
                style={{ fontSize: 14 }}
                columns={columns}
                data={vmsProfiles}
                options={{
                  sorting: true,
                  filtering: true,
                  showTitle: false,
                  maxBodyHeight: "66vh",
                  pageSize: 20,
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

//Profile.propTypes = {};

export default VmsProfile;
