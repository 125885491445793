import React, { useEffect, useState } from "react";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import ReferralsDocumentsDialogContent from "./ReferralsDocumentsDialogContent";

type ReferralsDocumentsDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (updatedEmail: string) => Promise<void>;
  referrerEmail: string;
  title: string;
  checkboxLabel: string;
  explanation: string;
};
const ReferralsDocumentsDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  explanation,
  checkboxLabel,
  referrerEmail,
}: ReferralsDocumentsDialogProps) => {
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updateEmail, setUpdateEmail] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleConfirm = async () => {
    setHasError(false);

    // Regex: a character before the @ and something before and after the dot in the domain part (\S means "anything but a space")
    if (updateEmail && (!updatedEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(updatedEmail))) {
      setHasError(true);
      return;
    }

    await onConfirm(updatedEmail);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setUpdatedEmail("");
      setUpdateEmail(false);
      setHasError(false);
    }
  }, [open]);

  return (
    <ConfirmDialog
      dialogTitle={title}
      content={
        <ReferralsDocumentsDialogContent
          referrerEmail={referrerEmail}
          setUpdatedEmail={setUpdatedEmail}
          updatedEmail={updatedEmail}
          setUpdateEmail={setUpdateEmail}
          updateEmail={updateEmail}
          hasError={hasError}
          checkboxLabel={checkboxLabel}
          explanation={explanation}
        />
      }
      isOpen={open}
      onConfirm={handleConfirm}
      onCancel={onClose}
    />
  );
};

export default ReferralsDocumentsDialog;
