import {
  AccountInfo,
  Configuration,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { ADAL_CLIENT_ID, ADAL_TENANT } from "./environment";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: ADAL_CLIENT_ID!,
    authority: `https://login.microsoftonline.com/${ADAL_TENANT}`, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const errorCodes = {
  forgotPassword: "AADB2C90118",
};

export const msalInstance = new PublicClientApplication(msalConfig);

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [],
};

const signInUserWithRedirect = (account: AccountInfo) => {
  msalInstance
    .acquireTokenRedirect({
      scopes: ["user.read"],
      account: account,
    })
    .catch((e) => console.error(e));
};

export const msalApiFetch = (url: string, options?: RequestInit): Promise<any> => {
  return new Promise((resolve, reject) => {
    const account = msalInstance.getAllAccounts()[0];

    msalInstance
      .acquireTokenSilent({ scopes: ["user.read"], account: account })
      .then((response) => {
        const bearer = `Bearer ${response.idToken}`;
        const o = options ?? {};
        if (!o.headers) o.headers = {};
        const reqHeaders = new Headers(o.headers);
        reqHeaders.set("Authorization", bearer);

        const selectedAgency = localStorage.getItem("selectedAgency");
        reqHeaders.set("Agency", selectedAgency ?? "");
        o.headers = reqHeaders;

        fetch(url, o)
          .then((response) => {
            if (response.status === 401) {
              signInUserWithRedirect(account);
              return;
            }

            if (response.status === 503 && !window.location.href.includes("/maintenance")) {
              window.location.href = "/maintenance";
              return;
            }

            return resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch(function (error) {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        console.error(error);
        if (error instanceof InteractionRequiredAuthError) {
          signInUserWithRedirect(account);
        }
      });
  });
};

export const getRoles = (roles?: string[]): string[] => {
  if (roles && Array.isArray(roles) && roles.length > 0) {
    return roles;
  } else {
    return [];
  }
};
