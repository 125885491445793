import React from "react";
import {
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { usePermissions } from "hooks";
import CanRenderChildren from "../functions/CanRenderChildren";

type PendingSignatureDialogProps = {
  open: boolean;
  onClose: () => void;
  timesheetId: string;
  onResend: () => void;
  onComplete: () => void;
  onReassignManager: () => void;
  resendLoading: boolean;
  completeLoading: boolean;
};

const PendingSignatureDialog = ({
  open,
  onClose,
  onResend,
  onComplete,
  onReassignManager,
  resendLoading,
  completeLoading,
}: PendingSignatureDialogProps): JSX.Element => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}>
        <DialogTitle id="alert-dialog-title">Pending Manager Signature</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={"span"}>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
              <CanRenderChildren permissionName="canReassignManager">
                <Button
                  variant="contained"
                  disabled={completeLoading || resendLoading}
                  onClick={onReassignManager}
                  style={{ marginBottom: "20px", width: "450px" }}>
                  Reassign manager signature
                </Button>
              </CanRenderChildren>
              <Button
                variant="contained"
                disabled={completeLoading || resendLoading}
                onClick={onResend}
                style={{ marginBottom: "20px", width: "450px" }}>
                {resendLoading ? (
                  <CircularProgress size={"20px"} />
                ) : (
                  "Resend signature request email to manager"
                )}
              </Button>
              <Button
                variant="contained"
                disabled={completeLoading || resendLoading}
                onClick={onComplete}
                style={{ width: "450px" }}>
                {completeLoading ? (
                  <CircularProgress size={"20px"} />
                ) : (
                  " Mark manager signature as completed"
                )}
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default PendingSignatureDialog;
