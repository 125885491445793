import React, { useEffect, useState } from "react";
import { TITLE_CHANGED } from "redux/actions/actionTypes";
import MaterialTable, { Column } from "material-table";
import TimecardFileIcon from "components/shared/components/TimecardFileIcon";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LoopIcon from "@mui/icons-material/Loop";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import PayrollApi from "api/PayrollApi";
import Avatar from "@mui/material/Avatar";
import { useSnackbar } from "notistack";
import NextPayPeriodApi from "api/NextPayPeriodApi";
import { TimesheetStatus, TimesheetSubStatus, TimesheetSubStatusMap } from "commons/Enums";
import ResetDidNotWorkButton from "components/shared/components/ResetDidNotWorkButton";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import TimesheetApi from "api/TimesheetApi";
import { useFeatureFlags } from "commons/Features";
import NextPayPeriodRejectContent from "./NextPayPeriodRejectContent";
import { useAppDispatch } from "hooks";

type NextPeriodTimesheet = {
  timesheetId: string;
  timecardUploadId: string | null;
  traveler: string;
  recruiter: string;
  jobId: string;
  status: string;
  subStatus: TimesheetSubStatus;
  timecardUploadSource: number | null;
};

const NextPayPeriodTimesheets = () => {
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();
  const [timesheets, setTimesheets] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [selectedTimesheetId, setSelectedTimesheetId] = useState<string | null>(null);
  const [textValue, setTextValue] = useState("");
  const { isNextPayPeriodViewRejectOn } = useFeatureFlags();

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Next Pay Period Timesheets" });
    getNextPayPeriodTimesheets();
  }, []);

  const getNextPayPeriodTimesheets = () => {
    NextPayPeriodApi.getNextPayPeriodTimesheets()
      .then((response) => {
        setTimesheets(response);
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const openConfirmDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const openConfirmRejectDialog = () => {
    setIsRejectDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setSelectedTimesheetId(null);
    setIsDeleteDialogOpen(false);
  };

  const closeRejectDialog = () => {
    setSelectedTimesheetId(null);
    setTextValue("");
    setIsRejectDialogOpen(false);
  };

  const handleDelete = (timesheetId: string) => {
    setSelectedTimesheetId(timesheetId);
    openConfirmDeleteDialog();
  };

  const handleReject = (timesheetId: string) => {
    setSelectedTimesheetId(timesheetId);
    openConfirmRejectDialog();
  };

  const handleOnTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
  };

  const onConfirmDelete = () => {
    if (selectedTimesheetId) {
      PayrollApi.deleteTimecardUpload(selectedTimesheetId)
        .then(() => {
          snackbar.enqueueSnackbar("The timecard file has been deleted");
          getNextPayPeriodTimesheets();
        })
        .catch((error) => {
          console.log(error);
          snackbar.enqueueSnackbar("An error occurred while deleting the file.");
        });
    }
  };

  const onConfirmReject = () => {
    if (!textValue) {
      snackbar.enqueueSnackbar("Please add a note before rejecting a timecard.");
      return;
    }
    const assignment = { id: selectedTimesheetId, note: textValue };
    TimesheetApi.reject(assignment)
      .then(async (results) => {
        if (!results.ok) throw results;
        snackbar.enqueueSnackbar("Timecard was rejected successfully.");
        getNextPayPeriodTimesheets();
      })
      .catch(() => {
        snackbar.enqueueSnackbar("An error occurred while rejecting the timecard.");
      });
  };

  const renderStatus = (status: string, timesheetId: string, subStatus: 0 | 1) => {
    if (status === TimesheetStatus.DRAFT) {
      return "Pending";
    } else if (status === TimesheetStatus.FOR_REVIEW) {
      return "Payroll";
    } else if (isNextPayPeriodViewRejectOn && status === TimesheetStatus.REJECTED) {
      return "Rejected";
    } else if (status === TimesheetStatus.NOTIMECARD) {
      return (
        <>
          {TimesheetSubStatusMap[subStatus]}{" "}
          <CanRenderChildren permissionName="canResetDidNotWork">
            <ResetDidNotWorkButton
              style={{ marginLeft: "5px" }}
              resetCallback={getNextPayPeriodTimesheets}
              timesheetSubStatus="DidNotWork"
              timesheetId={timesheetId}></ResetDidNotWorkButton>
          </CanRenderChildren>
        </>
      );
    }
    return status;
  };

  function loadColumns(): Column<NextPeriodTimesheet>[] {
    const columns: Column<NextPeriodTimesheet>[] = [
      {
        title: "Traveler",
        field: "traveler",
      },
      {
        title: "Recruiter",
        field: "recruiter",
      },
      {
        title: "Job ID",
        field: "jobId",
      },
      {
        title: "Status",
        field: "status",
        lookup: isNextPayPeriodViewRejectOn
          ? {
              Draft: "Pending",
              ForReview: "Payroll",
              DidNotWork: "Did Not Work",
              DidNotSubmit: "Did Not Submit",
              Rejected: "Rejected",
            }
          : {
              Draft: "Pending",
              ForReview: "Payroll",
              DidNotWork: "Did Not Work",
              DidNotSubmit: "Did Not Submit",
            },
        render: (rowData: NextPeriodTimesheet) =>
          renderStatus(rowData.status, rowData.timesheetId, rowData.subStatus),
      },
      {
        title: "Timecard File",
        field: "timecardUploadId",
        sorting: false,
        filtering: false,
        align: "center",
        render: (rowData: NextPeriodTimesheet) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {isNextPayPeriodViewRejectOn && rowData.status === TimesheetStatus.REJECTED ? (
              <Tooltip
                title={`This timecard has been ${rowData.status?.toLowerCase()}`}
                placement="top">
                <Avatar style={{ backgroundColor: "#f44336" }}>
                  <i className="material-icons-outlined">error_outline</i>
                </Avatar>
              </Tooltip>
            ) : (
              <TimecardFileIcon
                timecardUploadId={rowData.timecardUploadId}
                uploadSource={rowData.timecardUploadSource}
              />
            )}
            {isNextPayPeriodViewRejectOn ? (
              <IconButton
                disabled={
                  rowData.status === TimesheetStatus.REJECTED ||
                  rowData.status === TimesheetStatus.NOTIMECARD
                }
                onClick={() => handleReject(rowData.timesheetId)}
                style={{ marginLeft: "8px" }}
                size="large"
                data-testid="click-to-reject">
                <Tooltip title={"Click to Reject"} placement="top">
                  <LoopIcon />
                </Tooltip>
              </IconButton>
            ) : (
              <IconButton
                disabled={!rowData.timecardUploadId}
                onClick={() => handleDelete(rowData.timesheetId)}
                style={{ marginLeft: "8px" }}
                size="large"
                data-testid="click-to-delete">
                <Tooltip title={"Click to Delete"} placement="top">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            )}
          </div>
        ),
      },
    ];

    return columns;
  }

  return (
    <div className="view-container">
      <div style={{ display: "grid" }}>
        <MaterialTable
          style={{ overflow: "auto", fontSize: 14 }}
          columns={loadColumns()}
          data={timesheets}
          options={{
            sorting: true,
            filtering: true,
            showTitle: false,
            maxBodyHeight: "77vh",
            pageSize: 20,
          }}
        />
      </div>
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={onConfirmDelete}
        content="Are you sure you want to delete this timecard file?"
        dialogTitle="Delete Timecard File"
      />
      <ConfirmDialog
        isOpen={isRejectDialogOpen}
        onClose={closeRejectDialog}
        onConfirm={onConfirmReject}
        content={
          <NextPayPeriodRejectContent
            handleOnTextChange={handleOnTextChange}
            textValue={textValue}
          />
        }
        dialogTitle={"Reject Timecard"}
      />
    </div>
  );
};

export default NextPayPeriodTimesheets;
