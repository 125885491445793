import React from "react";
import { Paper, Typography } from "@mui/material";
import CallOffsSection from "./CallOffsSection";

type CallOffsSectionReadOnlyProps = {
  callOffs?: any[];
  isDigitalTimecardEntry: boolean;
};

const CallOffsSectionReadOnly = ({
  callOffs = [],
  isDigitalTimecardEntry,
}: CallOffsSectionReadOnlyProps) => {
  return (
    <Paper className="timecard-readonly-paper">
      <Typography
        sx={{
          padding: "10px",
        }}>
        Call Offs
      </Typography>
      <CallOffsSection
        callOffs={callOffs}
        isReadOnly
        isDigitalTimecardEntry={isDigitalTimecardEntry}
      />
    </Paper>
  );
};

export default CallOffsSectionReadOnly;
