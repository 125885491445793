import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

type MaterialTableLoaderProps = {
  message?: string;
};

export default function MaterialTableLoader({
  message = "Fetching data...",
}: MaterialTableLoaderProps) {
  return (
    // structure copied from original material-table overlay loading
    <Box sx={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", zIndex: 11 }}>
      <Box
        sx={{
          display: "table",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}>
        <Box
          sx={{
            display: "table-cell",
            width: "100%",
            height: "100%",
            verticalAlign: "middle",
            textAlign: "center",
          }}>
          <CircularProgress />
          <Typography>{message}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
