import React, { useState } from "react";
import { Button } from "@mui/material";
import ConfirmDialog from "components/shared/components/ConfirmDialog";
import UserNotificationsContent from "components/shared/components/message/UserNotificationsContent";
import ChatIcon from "@mui/icons-material/Chat";

type UserNotificationsProps = {
  timesheetId: string;
  hasUnreadMessages: boolean;
  candidateFullName: string;
  onMessagesRead: () => void;
  isReadOnly?: boolean;
};

const UserNotifications = ({
  hasUnreadMessages,
  timesheetId,
  candidateFullName,
  onMessagesRead,
  isReadOnly = false,
}: UserNotificationsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const chatIconColor = hasUnreadMessages ? "#7569b2" : "#6c757d";

  return (
    <>
      <Button
        sx={{ margin: 0, padding: 0, minWidth: 0, ml: 0.5 }}
        onClick={() => setIsModalOpen(!isModalOpen)}>
        <ChatIcon sx={{ color: chatIconColor, backgroundColor: "transparent" }} />
      </Button>

      <ConfirmDialog
        content={
          <UserNotificationsContent
            isModalOpen={isModalOpen}
            timesheetId={timesheetId}
            hasUnreadMessages={hasUnreadMessages}
            onMessagesRead={onMessagesRead}
            isReadOnly={isReadOnly}
          />
        }
        isOpen={isModalOpen}
        cancelText={undefined}
        onConfirm={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        displayCancelButton={false}
        maxWidth="md"
        fullWidth={true}
        dialogTitle={candidateFullName}
        dividerEnabled
        displayConfirmButton={false}
        displayCloseButton
        centerTitle={true}
      />
    </>
  );
};

export default UserNotifications;
