import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TimesheetApi from "api/TimesheetApi";
import {
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimecardHoursReadonly from "components/timecards/TimecardHoursReadonly";
import NonTaxableExpensesPanel from "components/timecards/NonTaxableExpensesPanel";
import TaxableExpensesReadonly from "components/timecards/TaxableExpensesReadonly";
import ExpensesPanel from "components/timecards/ExpensesPanel";
import TotalSummaryDetail from "components/timecards/TotalSummaryDetail";
import BillingHoursReadonly from "components/billing/BillingHoursReadonly";
import TimecardFile from "components/timecards/TimecardFile";
import FacilityReportFile from "components/timecards/FacilityReportFile";
import NotesSection from "components/notes/NotesSection";
import NoteTypeResolver from "components/notes/NoteTypeResolver";
import { useTimesheetTemplate } from "hooks";
import { useFeatureFlags } from "commons/Features";
import { TimesheetStatus } from "commons/Enums";
import { useDispatch } from "react-redux";
import { TIMESHEET_SELECTED } from "redux/actions/actionTypes";
import TimecardFileIcon from "components/shared/components/TimecardFileIcon";
import FacilityReportAttachedIcon from "components/shared/components/FacilityReportAttachedIcon";
import TimeCardAdjusmentsIcon from "components/shared/components/TimeCardAdjusmentsIcon";
import TimecardIsLateIcon from "components/shared/components/TimecardIsLateIcon";
import FileFordwardedIcon from "components/shared/components/FileFordwardedIcon";
import TimecardDidNotWork from "components/timecards/TimecardDidNotWork";
import SignatureIcon from "components/shared/components/SignatureIcon";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import CallOffsSectionReadOnly from "components/timecards/CallOffsSectionReadOnly";
import TimecardNotes from "components/notes/TimecardNotes";

export default function TravelerHistoryTimecardDetails(props) {
  const { partialTimesheetInfo, refreshJobAssignment } = props;

  const [fullTimesheet, setFullTimesheet] = useState(null);
  const [timesheetNotes, setTimesheetNotes] = useState([]);

  const { missedShiftDeductVisible, isExpensesOn, isAdjustmentsOn } = useFeatureFlags();

  const { isPayrollExpanded, handlePanelChange, showTimesheet, showNotes, isNoTimecardStatus } =
    useTimesheetTemplate(fullTimesheet);

  const dispatch = useDispatch();

  useEffect(() => {
    TimesheetApi.fetchTimecardDetail(partialTimesheetInfo.timesheetId).then((response) => {
      // in NonTaxableExpensesPanel component it's using another variable name
      response.nonTaxableExpenses = response.nonTaxableTypes;
      dispatch({
        type: TIMESHEET_SELECTED,
        patchedState: { timesheetSelected: response },
      });
      setFullTimesheet(response);
    });

    TimesheetApi.getNotesByAssigment(partialTimesheetInfo.timesheetId).then((data) => {
      setTimesheetNotes(data);
    });
  }, []);

  return !fullTimesheet ? (
    <div style={{ textAlign: "center" }}>
      <CircularProgress />
    </div>
  ) : (
    <div
      className="timecard-readonly"
      style={{
        overflow: "scroll",
        height: "60vh",
      }}>
      {isNoTimecardStatus && (
        <Paper className="timecard-readonly-paper">
          <TimecardDidNotWork></TimecardDidNotWork>
        </Paper>
      )}
      {!isNoTimecardStatus && showTimesheet && (
        <>
          <Grid container>
            <Grid
              xs={12}
              item
              justifyContent="flex-end"
              style={{ display: "flex", marginBottom: 15, gap: 5 }}>
              <TimecardIsLateIcon isLate={fullTimesheet.isLate}></TimecardIsLateIcon>

              <SignatureIcon
                signatureStatus={fullTimesheet.managerSignatureStatus}
                timesheetId={fullTimesheet.timeSheetId}
                timesheetStatus={fullTimesheet.hasEditRequest}
                refreshData={refreshJobAssignment}
              />

              <FacilityReportAttachedIcon
                timesheetId={fullTimesheet.timeSheetId}
                filled={!!fullTimesheet.facilityReportUploadId}></FacilityReportAttachedIcon>

              <TimecardFileIcon
                timecardUploadId={fullTimesheet.timecardUploadId}
                uploadSource={fullTimesheet.timecardUploadSource}></TimecardFileIcon>

              <FileFordwardedIcon
                timesheetId={fullTimesheet.timeSheetId}
                icon="forward_to_inbox"
                forwardingStatus={fullTimesheet.forwardingStatus}></FileFordwardedIcon>

              <CanRenderChildren featureFlagName="isAdjustmentsOn">
                <TimeCardAdjusmentsIcon
                  isAdjustment={fullTimesheet.isAdjustment}
                  hasAdjustment={fullTimesheet.hasAdjusments}
                  dtHoursAdjusted={fullTimesheet.dtHoursAdjusted}></TimeCardAdjusmentsIcon>
              </CanRenderChildren>
            </Grid>
          </Grid>
          <Accordion expanded={isPayrollExpanded} onChange={handlePanelChange("payroll")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>Payroll Timecard</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ flexDirection: "column" }}>
              {!fullTimesheet.isExpenseOnly && (
                <TimecardHoursReadonly
                  entries={fullTimesheet.timecardTypes}
                  adjustments={fullTimesheet.timecardAdjustmentTypes}
                  invoiceDelivery={
                    fullTimesheet.billingTypes != null
                      ? fullTimesheet.billingTypes.filter((x) => x.tableOrder === 0)[0]
                          .textFieldValue
                      : ""
                  }
                  missedLunchHours={fullTimesheet.missedLunchHours}></TimecardHoursReadonly>
              )}

              {missedShiftDeductVisible
                ? !fullTimesheet.isExpenseOnly && (
                    <NonTaxableExpensesPanel
                      legend={"Non Taxable"}
                      jobAssignment={fullTimesheet}></NonTaxableExpensesPanel>
                  )
                : !fullTimesheet.isExpenseOnly && (
                    <TaxableExpensesReadonly
                      expenses={fullTimesheet.nonTaxableTypes}
                      legend={"Non Taxable"}
                      isFirstWeek={fullTimesheet.isFirstWeek}
                      isLastWeek={fullTimesheet.isLastWeek}
                    />
                  )}

              {!fullTimesheet.isExpenseOnly && (
                <TaxableExpensesReadonly expenses={fullTimesheet.taxableTypes} legend={"Taxable"} />
              )}

              {isExpensesOn && fullTimesheet.expenses && fullTimesheet.expenses.length > 0 && (
                <ExpensesPanel expenses={fullTimesheet.expenses} legend={"Expenses"} />
              )}

              {fullTimesheet.hasBonusSection && !fullTimesheet.isExpenseOnly && (
                <TaxableExpensesReadonly expenses={fullTimesheet.bonusTypes} legend={"Bonus"} />
              )}

              {!fullTimesheet.isExpenseOnly && (
                <CallOffsSectionReadOnly
                  callOffs={fullTimesheet.callOffsTypes}
                  isDigitalTimecardEntry={fullTimesheet.isDigitalTimecardEntry}
                />
              )}

              <TotalSummaryDetail
                nonTaxableExpenses={fullTimesheet.nonTaxableTypes}
                taxableExpenses={fullTimesheet.taxableTypes}
                bonusExpenses={fullTimesheet.bonusTypes}
                timecardEntries={fullTimesheet.timecardTypes}
                adjustments={isAdjustmentsOn ? fullTimesheet.timecardAdjustmentTypes : []}
                expenses={fullTimesheet.expenses}
                nonTaxableAdjustments={fullTimesheet.nonTaxableAdjustmentTypes}
                legend={"Grand Total"}></TotalSummaryDetail>
            </AccordionDetails>
          </Accordion>

          {!fullTimesheet.isAdjustment && !fullTimesheet.isExpenseOnly && (
            <Accordion onChange={handlePanelChange("billing")}>
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                expandIcon={<ExpandMoreIcon />}>
                <Typography>Billing Timecard</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ flexDirection: "column" }}>
                <BillingHoursReadonly
                  rows={fullTimesheet.billingTypes}
                  isFirstWeek={fullTimesheet.isFirstWeek}
                />
              </AccordionDetails>
            </Accordion>
          )}

          {showTimesheet &&
            fullTimesheet.status !== TimesheetStatus.LATE &&
            !fullTimesheet.isExpenseOnly && (
              <>
                <TimecardFile
                  timesheetId={fullTimesheet.id}
                  timecardUploadId={fullTimesheet.timecardUploadId}
                  readonly={true}
                  isDigitalTimecardEntry={fullTimesheet.isDigitalTimecardEntry}
                />
                <FacilityReportFile
                  timesheetId={fullTimesheet.id}
                  facilityReportUploadId={fullTimesheet.facilityReportUploadId}
                  readonly={true}
                  isDigitalTimecardEntry={fullTimesheet.isDigitalTimecardEntry}
                />
              </>
            )}

          {(showNotes || (showTimesheet && fullTimesheet.status === TimesheetStatus.DRAFT)) &&
            !fullTimesheet.isExpenseOnly && (
              <>
                <TimecardNotes
                  inputAllowed={false}
                  notes={timesheetNotes}
                  noteTypeFilter={NoteTypeResolver.getNoteTypeFilterFromStatus(
                    fullTimesheet.status
                  )}
                />
              </>
            )}
        </>
      )}
    </div>
  );
}

TravelerHistoryTimecardDetails.propTypes = {
  partialTimesheetInfo: PropTypes.object,
  refreshJobAssignment: PropTypes.func,
};
