import React, { Component } from "react";
import NotesList from "./NotesList";
import Paper from "@mui/material/Paper";
import Textarea from "react-textarea-autosize";
import { Typography } from "@mui/material";
import _ from "lodash";
import NoteTypeResolver from "./NoteTypeResolver";

class NotesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.note || "",
      notesLoaded: false,
    };
    this.deb = _.debounce(this.props.onNoteChange, 250);
    this.deb = this.deb.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.note !== this.props.note) {
      this.setState({ text: this.props.note || "" });
      /* Here we set default value to '' because if left undefined some weird issues occured 
          with the textarea not updating the content properly */
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  handleNoteChange = (event) => {
    this.setState({ text: event.target.value });
    this.deb(event.target.value);
  };

  render() {
    const { notes, noteTypeFilter, isReadOnly } = this.props;
    const { text } = this.state;
    const inputAllowed = this.props.inputAllowed ?? true;

    return (
      <>
        <Paper>
          <div className="notes-section">
            <Typography>Notes</Typography>
            {inputAllowed && (
              <div className="note-section-textarea-container">
                <Textarea
                  value={text}
                  disabled={isReadOnly}
                  onChange={(e) => {
                    this.handleNoteChange(e);
                  }}
                  style={{ width: "100%", resize: "none", marginTop: "15px" }}
                  minRows={5}
                  maxRows={8}
                  placeholder="Personal Note"
                  maxLength="250"
                />
                <p className="note-characters-left">{250 - (text ? text.length : 0)}</p>
              </div>
            )}
            {noteTypeFilter.map((ntf, index) => (
              <div key={index}>
                <h4>{NoteTypeResolver.getNoteSourceFromNoteType(ntf)} Notes</h4>
                <NotesList key={index} notes={notes} noteTypeFilter={ntf} />
              </div>
            ))}
          </div>
        </Paper>
      </>
    );
  }
}

export default NotesSection;
