import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { saveHolidays } from "api/HolidaysApi";
import moment from "moment";
import React, { useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { Holiday } from "types/holidays";

type NextYearHolidayDialogProps = {
  onClose: () => void;
  open: boolean;
  toggleLoader: (a: boolean) => void;
  holiday: Holiday;
  onConfirm: () => void;
};

const NextYearHolidayDialog = ({
  open,
  onClose,
  toggleLoader,
  holiday,
  onConfirm,
}: NextYearHolidayDialogProps) => {
  const [holidayDate, setHolidayDate] = useState<Date>(holiday.date);
  const currentYear = new Date().getFullYear() + 1;

  const handleClose = () => {
    onClose();
  };

  const handleOnConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    setHolidayDate(new Date(holiday.date));
    handleClose();
  };
  const handleSave = async () => {
    toggleLoader(true);
    await saveHolidays(holiday.id, {
      ...holiday,
      date: holidayDate,
    });
    toggleLoader(false);
    handleOnConfirm();
  };

  const handleDayChange = (date: Date) => {
    setHolidayDate(date);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={"new-timecard-dialog"}>
      <DialogTitle id="form-dialog-title">Next year {holiday.description}</DialogTitle>
      <DialogContent>
        <Box justifyContent={"center"} display={"flex"}>
          <DayPickerInput
            value={holidayDate ? moment(holidayDate).format("MM/DD/YYYY") : undefined}
            onDayChange={handleDayChange}
            dayPickerProps={{
              disabledDays: {
                after: new Date(currentYear, 11, 31),
                before: new Date(currentYear, 0, 1),
              },
              selectedDays: holidayDate ? new Date(holidayDate) : undefined,
              initialMonth: new Date(holidayDate),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NextYearHolidayDialog;
