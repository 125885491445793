import React from "react";
import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import { UserStatus, UserTypeName, UsersTableItem } from "types/users";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";

const constants = {
  inactiveUserTooltip: "User has not completed the registration process.",
  activeUserTooltip: "User successfully registered.",
} as const;

export const bppEmailFormat = (value: unknown, row: UsersTableItem) => {
  const otherEmails = row.bppEmails.filter((x) => x !== row.email);
  if (otherEmails.length === 0) return row.email;

  return (
    <Tooltip
      title={otherEmails.map((x) => (
        <Box key={x}>{x}</Box>
      ))}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography fontSize={13}>{row.email}</Typography>
        <InfoOutlinedIcon color="action" fontSize="small" sx={{ marginLeft: 1 }} />
      </Box>
    </Tooltip>
  );
};

export const dateFormat = (value: unknown) => {
  if (!value) return "N/A";
  return moment(value as string).format("MM/DD/YYYY hh:mm A");
};

// eslint-disable-next-line react/display-name
export const actionsFormat =
  // eslint-disable-next-line react/display-name
  (toggleReset?: (user: UsersTableItem) => void) => (_: unknown, row: UsersTableItem) => {
    const isActive = row.status === UserStatus.Active;
    const canResetUser = !!row.externalId;

    return (
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <CanRenderChildren permissionName="canResetDtUsers">
          <Tooltip title={canResetUser ? "Reset user" : "User does not exist in DT"}>
            <span>
              <IconButton onClick={() => toggleReset?.(row)} disabled={!canResetUser}>
                <AutorenewOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CanRenderChildren>
        <Tooltip title={isActive ? constants.activeUserTooltip : constants.inactiveUserTooltip}>
          <PersonIcon color={isActive ? "action" : "error"} sx={{ marginLeft: 1 }} />
        </Tooltip>
      </Box>
    );
  };

// eslint-disable-next-line react/display-name
export const bppActionsFormat =
  // eslint-disable-next-line react/display-name
  (openModal?: (user: UsersTableItem) => void) => (_: unknown, row: UsersTableItem) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Tooltip title="User Details">
          <IconButton onClick={() => openModal?.(row)}>
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

export const userTypeFormat = (value: unknown) => {
  const userType = value as never;
  return <Typography>{UserTypeName[userType]}</Typography>;
};
