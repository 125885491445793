import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "timesheets/report/triage-exchange-report";
const jsonContentType = "application/json";

export function downloadExchangeTriagePlusReport(startDate: string, endDate: string) {
  const url = `${baseUrl}/${endpoint}?startDate=${startDate}&endDate=${endDate}`;
  return msalApiFetch(url, {
    method: "GET",
  }).then((response) => {
    if (!response.ok) throw response;
    return response.blob();
  });
}

export function getExchangeReportFileName(startDate: string) {
  const url = `${baseUrl}/${endpoint}/filename?startDate=${startDate}`;
  return msalApiFetch(url, {
    method: "GET",
    headers: { "Content-Type": jsonContentType },
  }).then((data) => {
    if (!data.ok) throw data;
    return data.json();
  });
}
