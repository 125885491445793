import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { getUserInfo, updateUserRole } from "api/UsersApi";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  RoleName,
  UserGroup,
  UserRoleInformation,
  UsersTableItem,
  rolesDescripcion,
} from "types/users";
import RecruiterListSelector from "./RecruiterListSelector";
import { Roles } from "commons/Enums";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import { usePermissions } from "hooks";

type BPPUserModalDetailProps = {
  user?: UsersTableItem;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  recruiters: UsersTableItem[];
};

const BPPUserModalDetail = ({
  user,
  onClose,
  onConfirm,
  open,
  recruiters,
}: BPPUserModalDetailProps) => {
  const [displayCheckBox, setDisplayCheckBox] = useState<boolean>(false);
  const [displayUserSelector, setDisplayUserSelector] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [userData, setUserData] = useState<UserRoleInformation>({ groupId: "" });
  const [originalUserRole, setOriginalUserRole] = useState<string>();
  const [selectedRecruiters, setSelectedRecruiters] = useState<UsersTableItem[]>();
  const { canUpdateBppUsers } = usePermissions();

  const isRecruiterOrTeamLead =
    userData?.groups?.find((x) => x.id === originalUserRole)?.name === Roles.Recruiter ||
    userData?.groups?.find((x) => x.id === originalUserRole)?.name === Roles.RecruiterTeamLead;
  const shouldDisableConfirm = isRecruiterOrTeamLead || loading;

  const checkUserRole = (
    selectedIdRole: string | undefined,
    userGroups: UserGroup[] | undefined
  ) => {
    const selectedRole = userGroups?.find((item) => item.id === selectedIdRole);

    setDisplayCheckBox((selectedRole && selectedRole?.canBePayPeriodUnlocker) ?? false);

    setDisplayUserSelector((selectedRole && selectedRole?.name === Roles.Assistant) ?? false);
  };

  const handleOnChange = (e: SelectChangeEvent) => {
    const selectedIdRole = e.target.value;

    checkUserRole(selectedIdRole, userData?.groups);
    userData && setUserData({ ...userData, groupId: selectedIdRole });
  };

  const getUserInformation = async () => {
    try {
      setLoading(true);
      if (user) {
        const response = await getUserInfo(user.email);

        if (!response.groupId) response.groupId = "";

        setUserData(response);
        checkUserRole(response.groupId, response.groups);
        setOriginalUserRole(response?.groupId);
      }
    } catch (error) {
      enqueueSnackbar("Error fetching user information", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, isPayPeriodUnlocker: e.target.checked });
  };

  const handleOnConfirmUserInformation = async () => {
    try {
      setLoading(true);
      if (userData) {
        const oldGroupId = originalUserRole != userData?.groupId ? originalUserRole ?? "" : "";

        const request = {
          userId: userData?.userId ?? "",
          newGroupId: userData?.groupId ?? "",
          oldGroupId: oldGroupId,
          IsPayPeriodUnlocker: userData?.isPayPeriodUnlocker || false,
          recruiters: selectedRecruiters?.map((item) => item.id),
        };

        await updateUserRole(request);
        onConfirm();
      }
    } catch (error) {
      enqueueSnackbar("Error updating user information", { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) getUserInformation();
  }, []);

  useEffect(() => {
    if (userData) {
      const selectedRecruiters = recruiters.filter((recruiter) =>
        userData.assignedRecruiters?.includes(recruiter.id)
      );
      setSelectedRecruiters(selectedRecruiters);
    }
  }, [userData]);

  const handleRecruitersSelected = (recruiters: UsersTableItem[]) => {
    setSelectedRecruiters(recruiters.sort((a, b) => a.name.localeCompare(b.name)));
  };

  return (
    <Box
      sx={{
        width: 750,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "space-around",
      }}>
      <FormControl sx={{ width: 400 }} margin="normal">
        <TextField
          InputProps={{
            readOnly: true,
          }}
          id="userEmail"
          label="USER EMAIL"
          value={user?.email}
        />
      </FormControl>
      {loading ? (
        <Skeleton height={100} width={400} />
      ) : (
        <Box>
          <FormControl sx={{ minWidth: 400 }} margin="normal">
            <InputLabel id="user-role-label">SELECT USER ROLE</InputLabel>
            <Select
              sx={{ textAlign: "left" }}
              placeholder="User Role"
              labelId="user-role-label"
              id="user-role-id"
              value={userData?.groupId}
              label="SELECT USER ROLE"
              disabled={!canUpdateBppUsers}
              onChange={handleOnChange}>
              {userData?.groups?.map((item: UserGroup) => (
                <MenuItem
                  value={item.id}
                  key={item.id}
                  disabled={item.name === Roles.RecruiterTeamLead || item.name === Roles.Recruiter}>
                  {rolesDescripcion[item.name as RoleName]}
                </MenuItem>
              ))}
            </Select>
            {displayCheckBox && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckedChange}
                    checked={userData?.isPayPeriodUnlocker}
                  />
                }
                label="Add pay period unlocker"
              />
            )}
          </FormControl>
          {displayUserSelector && (
            <RecruiterListSelector
              recruiters={recruiters}
              handleSelectedRecruiters={handleRecruitersSelected}
              selectedRecruiters={selectedRecruiters}
            />
          )}
        </Box>
      )}

      {isRecruiterOrTeamLead && (
        <Box>
          <Typography color="error" fontSize={12} mt={1}>
            {"This user's job title must be changed in Azure Entra in order to modify their role."}
          </Typography>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-around"}
        paddingTop={2}
        width={500}>
        <Button
          size="large"
          variant="outlined"
          style={{ color: "#514689", borderColor: "#514689" }}
          onClick={() => onClose()}>
          Cancel
        </Button>
        <CanRenderChildren permissionName="canUpdateBppUsers">
          <Button
            size="large"
            variant="contained"
            onClick={() => handleOnConfirmUserInformation()}
            disabled={shouldDisableConfirm}>
            Confirm
          </Button>
        </CanRenderChildren>
      </Box>
    </Box>
  );
};

export default BPPUserModalDetail;
