import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import logo from "./logo.png";
import Home from "./components/home/Home";
import PrivateRoute from "./components/shared/routes/privateRoute";
import { Roles } from "./commons/Enums";
import Profile from "./components/profile/Profile";
import VmsProfile from "./components/vmsProfile/VmsProfile";
import History from "./components/history/History";
import AdvancedSearchHistory from "./components/history/AdvancedSearchHistory";
import Facilities from "./components/facilities/Facilities";
import { Maintenance } from "./components/maintenance/Maintenance";
import { useFeatureFlags } from "./commons/Features";
import FacilityDetail from "./components/facilities/FacilityDetail";
import Dashboard from "./components/dashboard/Dashboard";
import UnlockPayPeriod from "./components/unlock/UnlockPayPeriod";
import Expenses from "./components/expenses/Expenses";
import ExpenseDetails from "./components/expenses/ExpenseDetails";
import { loginRequest, msalInstance } from "./commons/authConfig";
import { InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import Header from "./components/shared/components/Header";
import ListItemButton from "@mui/material/ListItemButton";
import { ManageUsers } from "components/manageUsers";
import { useDispatch } from "react-redux";
import {
  APP_SETTINGS_UPDATED,
  FEATURE_FLAGS_CHANGED,
  MISSED_SHIFT_DATE_CONFIG_UPDATED,
  PERMISSIONS_CHANGED,
} from "./redux/actions/actionTypes";
import FeaturesApi from "./api/FeaturesApi";
import CanRenderChildren from "./components/shared/functions/CanRenderChildren";
import TravelerHistory from "./components/history/TravelerHistory";
import SettingsApi from "api/SettingsApi";
import NextPayPeriodTimesheets from "components/nextPayPeriodTimesheets/NextPayPeriodTimesheets";
import Unauthorized from "components/unauthorized/Unauthorized";
import Referrals from "components/referrals/Referrals";
import { Contracts } from "components/contracts/Contracts";
import { ContractDetails } from "components/contracts/ContractDetails";
import ReferralDetail from "components/referrals/ReferralDetail";
import AdfRuns from "components/adfRuns/AdfRuns";
import { Tooltip } from "@mui/material";
import SwitchAgencyDialog from "components/shared/components/SwitchAgencyDialog";

const drawerWidth = 70;
const drawerWidthExpanded = 190;

function MenuListItem(props) {
  function handleClick(e) {
    if (props.oc === "logout") {
      msalInstance.logoutRedirect({ account: msalInstance.getActiveAccount() }).catch((e) => {
        console.error(e);
      });
    }
    if (props.oc === "toogleMenu" || props.oc === "switchAgency") {
      props.toggleMenuExpand();
      e.preventDefault();
    }

    if (props.to) props.onPathChange?.(props.to);
  }

  const isCurrentPath = props.to.includes(props.currentLocation);
  const tootltipTitle = props.isMenuExpanded ? undefined : props.title;

  return (
    <ListItemButton
      sx={{ color: isCurrentPath ? "black" : "inherit", padding: "10px 0px", left: "auto" }}
      onClick={handleClick}
      component={NavLink}
      to={props.to}
      selected={isCurrentPath}>
      <Tooltip
        title={tootltipTitle}
        placement="right"
        arrow
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -10],
                },
              },
            ],
          },
        }}>
        <li
          style={{
            paddingLeft: "20px",
            paddingRight: "30px",
            fontSize: "0.8em",
            overflow: "hidden",
            textAlign: "center",
          }}>
          <i className="material-icons-outlined" style={{ fontSize: "1.9rem" }}>
            {props.icon}
          </i>
        </li>
      </Tooltip>
      {props.isMenuExpanded && (
        <li
          style={{
            textAlign: "left",
            fontSize: "0.8em",
            overflow: "hidden",
            width: props.isMenuExpanded ? "100%" : "0px",
          }}>
          {props.title}
        </li>
      )}
    </ListItemButton>
  );
}

export default function PermanentDrawerLeft() {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
  useEffect(() => {
    if (error && error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, loginRequest).catch((err) => {
        console.error(err);
      });
    }
  }, [error]);

  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState();
  const { featureFlags } = useFeatureFlags();

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  useEffect(() => {
    dispatch({
      type: PERMISSIONS_CHANGED,
      roles: msalInstance.getActiveAccount().idTokenClaims.roles,
    });

    FeaturesApi.getFeatures().then((data) => {
      dispatch({ type: FEATURE_FLAGS_CHANGED, featuresFlags: data });
      dispatch({ type: MISSED_SHIFT_DATE_CONFIG_UPDATED, featuresFlags: data });
    });
    SettingsApi.getSettings().then((data) => {
      dispatch({ type: APP_SETTINGS_UPDATED, appSettings: data });
    });
  }, []);

  const onPathChange = (nextPath) => {
    setCurrentLocation(nextPath);
  };

  const user = msalInstance.getActiveAccount();

  const drawerPaperPosition = isMenuExpanded ? "relative" : "inherit";
  const drawerFinalWidth = isMenuExpanded ? drawerWidthExpanded : drawerWidth;
  const [showDrawer, setShowDrawer] = useState(true);
  const [isSwitchAgencyDialogOpen, setIsSwitchAgencyDialogOpen] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("adf-runs")) {
      setShowDrawer(false);
    }
  }, []);

  return (
    <Router>
      <div
        style={{
          display: "flex",
          height: "100%",
        }}>
        {showDrawer && (
          <Drawer
            sx={{
              width: drawerFinalWidth,
              flexShrink: 0,
              zIndex: 52,
            }}
            variant="permanent"
            PaperProps={{
              elevation: 2,
              sx: {
                width: drawerFinalWidth,
                position: drawerPaperPosition,
              },
            }}
            anchor="left">
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                height: isMenuExpanded ? "50px" : "40px",
                paddingTop: isMenuExpanded ? "5px" : "15px",
                paddingLeft: "3px",
                paddingRight: "3px",
                textAlign: "center",
              }}>
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                src={logo}
                alt="logo"
              />
            </div>

            <List
              sx={{
                color: "rgba(0, 0, 0, 0.54)",
                display: "flex",
                flexDirection: "column",
              }}>
              <CanRenderChildren permissionName="canSeeDashboard">
                <MenuListItem
                  to="/home"
                  title="Home"
                  icon="pending"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren permissionName="canSeeProfile">
                <MenuListItem
                  to="/profile"
                  title="Profile"
                  icon="person"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren permissionName="canOnlySeeTimesheetProcessing">
                <MenuListItem
                  to={`/home/${user?.idTokenClaims?.oid}`}
                  title="Profile"
                  icon="person"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren permissionName="canSeeVms" featureFlagName="isVmsOn">
                <MenuListItem
                  to="/vmsprofile"
                  title="Vms"
                  icon="store"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren featureFlagName="isHistoryOn">
                <MenuListItem
                  to="/history"
                  title="History"
                  icon="history"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren featureFlagName="isTravelerHistoryAdvancedSearchOn">
                <MenuListItem
                  to="/advanced-search"
                  title="Advanced Search"
                  icon="search"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren
                permissionName="canManageFacilities"
                featureFlagName="isFacilitiesOn">
                <MenuListItem
                  to="/facilities"
                  title="Facilities"
                  icon="business"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren permissionName="canUnlockPayPeriod">
                <MenuListItem
                  to="/pay-period-settings"
                  title="Pay Period Settings"
                  icon="event_repeat"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren permissionName="canSeeExpenses" featureFlagName="isExpensesOn">
                <MenuListItem
                  to="/expenses"
                  title="Expenses"
                  icon="paid"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren
                featureFlagName="isNextPayPeriodViewOn"
                permissionName="canCheckNextPayPeriodTimesheets">
                <MenuListItem
                  to="/next-pay-period-timesheets"
                  title="Next Pay Period Timesheets"
                  icon="next_week"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren featureFlagName="isReferralsOn" permissionName="canSeeReferrals">
                <MenuListItem
                  to="/referrals"
                  title="Referrals"
                  icon="group_add"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren permissionName="canSeeContracts">
                <MenuListItem
                  to="/contracts"
                  title="Contracts"
                  icon="description"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>

              <CanRenderChildren permissionName="canManageUsers">
                <MenuListItem
                  to="/manage-users"
                  title="Manage Users"
                  icon="group"
                  isMenuExpanded={isMenuExpanded}
                  onPathChange={onPathChange}
                  currentLocation={currentLocation}
                />
              </CanRenderChildren>
            </List>
            <List style={{ marginTop: `auto`, display: "flex", flexDirection: "column" }}>
              <MenuListItem
                oc="toogleMenu"
                toggleMenuExpand={() => {
                  setIsMenuExpanded(!isMenuExpanded);
                }}
                to=""
                title={`${isMenuExpanded ? "Collapse" : "Expand"}`}
                isMenuExpanded={isMenuExpanded}
                icon={`${isMenuExpanded ? "keyboard_arrow_left" : "keyboard_arrow_right"}`}
              />
              <CanRenderChildren permissionName="canSwitchAgency">
                <MenuListItem
                  oc="switchAgency"
                  toggleMenuExpand={() => {
                    setIsSwitchAgencyDialogOpen(true);
                  }}
                  to=""
                  title="Switch Agency"
                  isMenuExpanded={isMenuExpanded}
                  icon="switch_account"
                />
              </CanRenderChildren>
              <MenuListItem
                oc="logout"
                to=""
                title={user?.idTokenClaims?.name ?? ""}
                isMenuExpanded={isMenuExpanded}
                icon="exit_to_app"
              />
            </List>
          </Drawer>
        )}
        <main
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}>
          <Header />
          <CanRenderChildren permissionName="canSwitchAgency">
            <SwitchAgencyDialog
              open={isSwitchAgencyDialogOpen}
              onClose={() => setIsSwitchAgencyDialogOpen(false)}
            />
          </CanRenderChildren>
          <PrivateRoute
            exact
            path="/home/:recruiterId"
            featureFlags={featureFlags}
            component={Home}
          />
          <PrivateRoute
            exact
            path="/home/:recruiterId/:salesRepSelected"
            featureFlags={featureFlags}
            component={Home}
          />
          <PrivateRoute
            exact
            path="/home/:recruiterId/:selectedAssignmentId/:fdMonth/:fdDay/:fdYear/:ldMonth/:ldDay/:ldYear"
            featureFlags={featureFlags}
            component={Home}
          />
          <PrivateRoute exact path="/vms/:vmsId" component={Home} featureFlags={featureFlags} />
          <PrivateRoute
            exact
            path="/home"
            roles={[Roles.SuperAdmin, Roles.Admin, Roles.TriagePlusSuperAdmin]}
            component={Dashboard}
            featureFlags={featureFlags}
          />
          <PrivateRoute
            path="/profile"
            roles={[
              Roles.SuperAdmin,
              Roles.Admin,
              Roles.RecruiterTeamLead,
              Roles.Assistant,
              Roles.TriagePlusSuperAdmin,
            ]}
            component={Profile}
            featureFlags={featureFlags}
          />
          <PrivateRoute
            path="/vmsprofile"
            roles={[Roles.SuperAdmin, Roles.Admin, Roles.TriagePlusSuperAdmin]}
            component={VmsProfile}
            featureFlags={featureFlags}
          />
          <CanRenderChildren featureFlagName="isHistoryOn">
            <PrivateRoute
              path="/history"
              roles={[
                Roles.SuperAdmin,
                Roles.Admin,
                Roles.RecruiterTeamLead,
                Roles.Recruiter,
                Roles.SalesRep,
                Roles.Assistant,
                Roles.TriagePlusSuperAdmin,
              ]}
              component={History}
              featureFlags={featureFlags}
            />
          </CanRenderChildren>

          <CanRenderChildren featureFlagName="isTravelerHistoryAdvancedSearchOn">
            <PrivateRoute
              path="/advanced-search"
              roles={[
                Roles.SuperAdmin,
                Roles.Admin,
                Roles.RecruiterTeamLead,
                Roles.Recruiter,
                Roles.SalesRep,
                Roles.Assistant,
                Roles.TriagePlusSuperAdmin,
              ]}
              component={AdvancedSearchHistory}
              featureFlags={featureFlags}
            />
          </CanRenderChildren>

          <CanRenderChildren featureFlagName="isTravelerHistoryAdvancedSearchOn">
            <PrivateRoute
              path="/traveler-history/:jobId"
              roles={[
                Roles.SuperAdmin,
                Roles.Admin,
                Roles.RecruiterTeamLead,
                Roles.Recruiter,
                Roles.SalesRep,
                Roles.Assistant,
                Roles.TriagePlusSuperAdmin,
              ]}
              component={TravelerHistory}
              featureFlags={featureFlags}
            />
          </CanRenderChildren>

          <CanRenderChildren featureFlagName="isFacilitiesOn">
            <PrivateRoute
              exact
              path="/facilities"
              roles={[Roles.SuperAdmin, Roles.Admin, Roles.TriagePlusSuperAdmin]}
              component={Facilities}
              featureFlags={featureFlags}
            />
            <PrivateRoute
              exact
              path="/facilities/:facilityId"
              roles={[Roles.SuperAdmin, Roles.Admin, Roles.TriagePlusSuperAdmin]}
              component={FacilityDetail}
              featureFlags={featureFlags}
            />
          </CanRenderChildren>
          <CanRenderChildren permissionName="canUnlockPayPeriod">
            <PrivateRoute
              path="/pay-period-settings"
              roles={[Roles.SuperAdmin, Roles.PayPeriodUnlocker]}
              component={UnlockPayPeriod}
            />
          </CanRenderChildren>
          <CanRenderChildren featureFlagName="isExpensesOn" permissionName="canSeeExpenses">
            <PrivateRoute
              path="/expenses"
              roles={[
                Roles.SuperAdmin,
                Roles.Admin,
                Roles.Recruiter,
                Roles.RecruiterTeamLead,
                Roles.SalesRep,
                Roles.Assistant,
              ]}
              component={Expenses}
            />
            <PrivateRoute
              exact
              path="/expenseDetails"
              roles={[
                Roles.SuperAdmin,
                Roles.Admin,
                Roles.Recruiter,
                Roles.RecruiterTeamLead,
                Roles.SalesRep,
                Roles.Assistant,
              ]}
              component={ExpenseDetails}
            />
            <PrivateRoute
              exact
              path="/expenseDetails/:expenseId"
              roles={[
                Roles.SuperAdmin,
                Roles.Admin,
                Roles.Recruiter,
                Roles.RecruiterTeamLead,
                Roles.Assistant,
                Roles.SalesRep,
              ]}
              component={ExpenseDetails}
            />
          </CanRenderChildren>
          <CanRenderChildren
            featureFlagName="isNextPayPeriodViewOn"
            permissionName="canCheckNextPayPeriodTimesheets">
            <PrivateRoute
              path="/next-pay-period-timesheets"
              roles={[Roles.SuperAdmin, Roles.TriagePlusSuperAdmin]}
              component={NextPayPeriodTimesheets}
              featureFlags={featureFlags}
            />
          </CanRenderChildren>

          <CanRenderChildren featureFlagName="isNextPayPeriodViewOn">
            <PrivateRoute
              exact
              path="/referrals"
              component={Referrals}
              roles={[
                Roles.SuperAdmin,
                Roles.Admin,
                Roles.RecruiterTeamLead,
                Roles.Recruiter,
                Roles.SalesRep,
                Roles.Assistant,
              ]}
            />
            <PrivateRoute
              exact
              path="/referrals/:dealId"
              component={ReferralDetail}
              roles={[Roles.SuperAdmin]}
            />
          </CanRenderChildren>

          <CanRenderChildren permissionName="canSeeContracts">
            <PrivateRoute
              exact
              path="/contracts"
              component={Contracts}
              roles={[Roles.SuperAdmin, Roles.Admin, Roles.TriagePlusSuperAdmin]}
            />
          </CanRenderChildren>

          <CanRenderChildren permissionName="canManageUsers">
            <PrivateRoute
              exact
              path="/manage-users"
              component={ManageUsers}
              roles={[Roles.SuperAdmin, Roles.Admin]}
            />
          </CanRenderChildren>

          <CanRenderChildren permissionName="canManageContracts">
            <PrivateRoute
              exact
              path="/contracts/contractDetails"
              component={ContractDetails}
              roles={[Roles.SuperAdmin]}
            />
            <PrivateRoute
              exact
              path="/contracts/contractDetails/:contractId"
              component={ContractDetails}
              roles={[Roles.SuperAdmin, Roles.TriagePlusSuperAdmin]}
            />
          </CanRenderChildren>

          <PrivateRoute exact path="/adf-runs/:id" component={AdfRuns} />

          <Route path="/unauthorized" component={Unauthorized} />
          <Route path="/maintenance" component={Maintenance} />
        </main>
      </div>
    </Router>
  );
}

MenuListItem.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  isMenuExpanded: PropTypes.bool,
  toggleMenuExpand: PropTypes.func,
  oc: PropTypes.string,
  onPathChange: PropTypes.func,
  currentLocation: PropTypes.string,
};
