/* eslint-disable no-nested-ternary */
import { Input, TableCell, TableRow } from "@mui/material";
import React from "react";
import TimecardRowInformation from "components/shared/components/TimecardRowInformation";
import { Box } from "@mui/system";
import NumberFormat from "react-number-format";

type CallOffsSectionRowProps = {
  row: any;
  isReadOnly?: boolean;
  handleCallOffChange?: (name: string, value: number | undefined) => void;
  isDigitalTimecardEntry: boolean;
};

const CallOffsSectionRow = ({
  row,
  isReadOnly = false,
  handleCallOffChange = () => {},
  isDigitalTimecardEntry,
}: CallOffsSectionRowProps) => {
  const shouldDisplayDTIndicator = isDigitalTimecardEntry && row.hours !== row.originalHours;
  return (
    <TableRow key={row.name}>
      <TableCell component="th" scope="row">
        <label> {row.title} </label>

        {shouldDisplayDTIndicator && (
          <TimecardRowInformation
            informationText="Digital Timecard original amount:"
            informationValue={row?.originalHours}
          />
        )}
      </TableCell>

      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
          }}>
          {!row.isLocked && !isReadOnly ? (
            <NumberFormat
              customInput={Input}
              fixedDecimalScale={true}
              decimalScale={2}
              value={row.hours}
              onValueChange={(value) => {
                handleCallOffChange(row.name, value.floatValue);
              }}
              thousandSeparator={true}
              onClick={(e: any) => {
                e.target.select();
              }}
              onBlur={(e: any) => {
                if (e.target.value === "") {
                  handleCallOffChange(row.name, 0);
                }
              }}
              defaultValue={0}
              allowEmptyFormatting={false}
            />
          ) : (
            <NumberFormat
              fixedDecimalScale={true}
              decimalScale={2}
              value={row.hours}
              thousandSeparator={true}
              readOnly={true}
              displayType={"text"}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default CallOffsSectionRow;
