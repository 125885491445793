import { Box, FormControl, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { useAppSelector } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { EXPENSE_FILTERS_CHANGED_AMOUNT } from "redux/actions/actionTypes";

const AmountRange = () => {
  const expensesFilters = useAppSelector((state: RootState) => state.expensesState);
  const dispatch = useDispatch();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    const regex = /(\d\d{0,2}|1000)/;

    if (value != "" && !regex.test(value)) {
      return;
    }

    let inputValue = value === "" ? undefined : Number(value);

    if (inputValue !== undefined) {
      inputValue = Math.trunc(Math.max(0, Math.min(1000, inputValue)));
    }
    const isMinAmount = id === "amount-min-adorment";
    const amountFrom = isMinAmount ? inputValue : expensesFilters.amountFrom;
    const amountTo = isMinAmount ? expensesFilters.amountTo : inputValue;

    dispatch({
      type: EXPENSE_FILTERS_CHANGED_AMOUNT,
      amountFrom,
      amountTo,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}>
      <FormControl sx={{ mr: 1, width: 120 }} size="small">
        <InputLabel htmlFor="amount-min-adorment">Min Amount</InputLabel>
        <OutlinedInput
          type="number"
          id="amount-min-adorment"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Min Amount"
          onChange={handleInputChange}
          value={expensesFilters.amountFrom}
        />
      </FormControl>
      <FormControl sx={{ mr: 1, width: 120 }} size="small">
        <InputLabel htmlFor="amount-max-adorment">Max Amount</InputLabel>
        <OutlinedInput
          type="number"
          id="amount-max-adorment"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Max Amount"
          onChange={handleInputChange}
          value={expensesFilters.amountTo}
        />
      </FormControl>
    </Box>
  );
};

export default AmountRange;
