import React, { useEffect, useState } from "react";
import { JobAssignmentNote } from "./JobAssignmentNote";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Box, Skeleton } from "@mui/material";
import { usePermissions } from "hooks";
import { Note } from "types/notes";
import { GetAssignmentNotes } from "api/NotesApi";
import { NewNoteInput } from "./NewNoteInput";
import { parseErrorResponse } from "commons/utils";
import { useSnackbar } from "notistack";

type HeaderNotesListProps = {
  jobAssignmentId: string;
  assignmentsFetched: (count: number) => void;
  isReadOnly: boolean;
};

const ERROR_FETCHING_NOTES = "Cannot retrieve job assignment notes.";

export const HeaderNotesList = ({
  jobAssignmentId,
  assignmentsFetched,
  isReadOnly,
}: HeaderNotesListProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { canSaveAssignmentNotes } = usePermissions();
  const [isLoading, setIsLoading] = useState(!isReadOnly);
  const [notes, setNotes] = useState<Note[]>([]);

  const fetchNotes = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const notes = await GetAssignmentNotes(jobAssignmentId);
      setNotes(notes);
      assignmentsFetched(notes?.length ?? 0);
    } catch (error: any) {
      const errorMessage = await parseErrorResponse(error, ERROR_FETCHING_NOTES);
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [jobAssignmentId]);

  return isLoading ? (
    <Skeleton height={95} />
  ) : (
    <div>
      <Box display="flex" alignItems="center">
        <Typography fontWeight={500}>Notes</Typography>
      </Box>

      <Box display="flex">
        <List style={{ maxWidth: "100%", resize: "none" }}>
          {notes.map((note: Note) => (
            <JobAssignmentNote key={note.noteId} note={note} />
          ))}
        </List>
      </Box>

      {canSaveAssignmentNotes && !isReadOnly && (
        <NewNoteInput jobAssignmentId={jobAssignmentId} noteSaved={fetchNotes} />
      )}
    </div>
  );
};
