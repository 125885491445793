import { msalApiFetch } from "../commons/authConfig";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "dataexports";

function getDataExport(query: any, contentType: string) {
  const url = `${baseUrl}/${endpoint}`;
  return msalApiFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: contentType,
    },
    body: JSON.stringify(query),
  }).then((response) => response.blob());
}

async function getAdpReport(lateTimecards: boolean): Promise<Blob> {
  const url = `${process.env.REACT_APP_API_URL}/report/adp?lateTimecards=${lateTimecards}`;

  const response = await msalApiFetch(url, { method: "GET" });

  if (!response.ok) throw response;

  const blob = await response.blob();

  return blob;
}

export { getDataExport, getAdpReport };
