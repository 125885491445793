import { TimesheetStatus } from "commons/Enums";
import LoggedUser from "commons/LoggedUser";
import { useEffect, useState } from "react";

type PartialTimesheet = {
  hasEditRequest: boolean;
  status: string;
  noExpenses: boolean;
  timecardUploadId: string;
  isAdjustment: boolean;
  hasToAcknowledgeNotes: boolean;
};

export default function useTimesheetTemplate(timesheet: PartialTimesheet | null) {
  const [isPayrollExpanded, setIsPayrollExpanded] = useState(false);
  const [isBillingExpanded, setIsBillingExpanded] = useState(false);
  const [hasRecruiterVisibility, setHasRecruiterVisibility] = useState(false);
  const [hasExpenses, setHasExpenses] = useState(false);
  const [hasTimecardUpload, setHasTimecardUpload] = useState(false);
  const [showTimesheet, setShowTimesheet] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showNotesAcknowledgement, setShowNotesAcknowledgement] = useState(false);
  const [isNoTimecardStatus, setIsNoTimecardStatus] = useState(false);

  const isRecruiter = LoggedUser.isRecruiter();
  const isAssistant = LoggedUser.isAssistant();
  const isSalesRep = LoggedUser.isSalesRep();
  const isRecruiterTeamLead = LoggedUser.isRecruiterTeamLead();

  const handlePanelChange =
    (panel: "payroll" | "billing") => (event: React.ChangeEvent<any>, isExpanded: boolean) => {
      if (panel === "payroll") {
        setIsPayrollExpanded(isExpanded);
      } else if (panel === "billing") {
        setIsBillingExpanded(isExpanded);
      }
    };

  useEffect(() => {
    setShowNotesAcknowledgement(!!timesheet && timesheet.hasToAcknowledgeNotes);
    setHasRecruiterVisibility(
      !!timesheet &&
        (timesheet.status === TimesheetStatus.RETURNED ||
          timesheet.status === TimesheetStatus.REJECTED ||
          timesheet.hasEditRequest ||
          timesheet.status === TimesheetStatus.FOR_REVIEW ||
          timesheet.status === TimesheetStatus.APPROVED ||
          timesheet.status === TimesheetStatus.LATE ||
          timesheet.status === TimesheetStatus.NOTIMECARD)
    );

    setHasExpenses(
      !!timesheet && !timesheet.noExpenses && timesheet.status !== TimesheetStatus.DRAFT
    );

    setHasTimecardUpload(!!timesheet && !!timesheet.timecardUploadId);

    setIsNoTimecardStatus(!!timesheet && timesheet.status === TimesheetStatus.NOTIMECARD);

    setIsPayrollExpanded(false);
    setIsBillingExpanded(false);
  }, [timesheet]);

  useEffect(() => {
    setShowTimesheet(
      !showNotesAcknowledgement &&
        (((isRecruiter || isSalesRep || isAssistant || isRecruiterTeamLead) &&
          hasRecruiterVisibility) ||
          !(isRecruiter || isSalesRep || isAssistant || isRecruiterTeamLead) ||
          (!!timesheet &&
            timesheet.status === TimesheetStatus.DRAFT &&
            (hasTimecardUpload || hasExpenses || timesheet.isAdjustment)))
    );

    setShowNotes(
      ((isRecruiter || isSalesRep || isAssistant) && hasRecruiterVisibility) ||
        (!(isRecruiter || isSalesRep || isAssistant) &&
          !!timesheet &&
          timesheet.status !== TimesheetStatus.DRAFT)
    );
  }, [
    isRecruiter,
    isSalesRep,
    isAssistant,
    isRecruiterTeamLead,
    hasRecruiterVisibility,
    timesheet,
    hasTimecardUpload,
    hasExpenses,
    showNotesAcknowledgement,
  ]);

  return {
    isPayrollExpanded,
    isBillingExpanded,
    handlePanelChange,
    showTimesheet,
    showNotes,
    showNotesAcknowledgement,
    isNoTimecardStatus,
  };
}
