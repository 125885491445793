import { Box } from "@mui/system";
import PayPeriodRangeSelector from "components/history/PayPeriodRangeSelector";
import RecruiterSearch from "components/history/RecruiterSearch";
import { useAppSelector } from "hooks";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EXPENSE_FILTERS_CHANGED_DATES,
  EXPENSE_FILTERS_CHANGED_RECRUITER,
  EXPENSE_FILTERS_CHANGED_TRAVELER,
} from "redux/actions/actionTypes";
import { OptionType } from "types/expenses/Expense";
import { RecruiterState } from "types/recruiter";
import StatusFilter from "./StatusFilter";
import ExpenseTypeSearch from "./ExpenseTypeSearch";
import ExpensesApi from "../../../api/ExpensesApi";
import TravelerSearch from "components/history/TravelerSearch";
import { TravelerState } from "types/traveler";
import AmountRange from "./AmountRange";
import LoggedUser from "commons/LoggedUser";
import moment from "moment";

type ExpensesFilters = {
  expenseStatus: OptionType[];
};

const ExpenseFilters = ({ expenseStatus }: ExpensesFilters) => {
  const [expenseTypes, setExpenseTypes] = useState<OptionType[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const headerEndDate = useSelector((state: RootState) => state.header.endDate);
  const maxDateFilters = moment(headerEndDate !== "" ? headerEndDate : new Date().toISOString())
    .add(6, "months")
    .day(6)
    .toDate();

  const dispatch = useDispatch();
  const isSuperAdmin = LoggedUser.isSuperAdmin();
  const isAdmin = LoggedUser.isAdmin();

  const expensesFilters = useAppSelector((state: RootState) => state.expensesState);

  const handleOnFilterDateChange = (dateFrom?: string, dateTo?: string) => {
    dispatch({
      type: EXPENSE_FILTERS_CHANGED_DATES,
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
  };

  const handleRecruiterChange = (_: SyntheticEvent<Element, Event>, newValue: RecruiterState) => {
    dispatch({
      type: EXPENSE_FILTERS_CHANGED_RECRUITER,
      recruiters: newValue,
    });
  };

  const handleTravelerChange = (_: SyntheticEvent<Element, Event>, newValue: TravelerState) => {
    dispatch({
      type: EXPENSE_FILTERS_CHANGED_TRAVELER,
      travelers: newValue,
    });
  };

  const getExpenseTypes = async () => {
    const resultExpenseTypes = await ExpensesApi.getExpenseTypes();
    if (isSuperAdmin || isAdmin) {
      setExpenseTypes(resultExpenseTypes.expenseTypes);
    } else {
      setExpenseTypes([
        ...resultExpenseTypes.expenseTypes,
        { id: "00000000-0000-0000-0000-000000000000", name: "Blank" },
      ]);
    }
  };

  useEffect(() => {
    getExpenseTypes();
  }, []);

  return (
    <Box display={"flex"} flexDirection="column">
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        <StatusFilter expenseStatus={expenseStatus} />
        <Box sx={{ width: 250, mr: 1 }}>
          <TravelerSearch
            currentTraveler={expensesFilters.travelers}
            handleChange={handleTravelerChange}
          />
        </Box>
        <Box sx={{ width: 250, mr: 1 }}>
          <RecruiterSearch
            currentRecruiter={expensesFilters.recruiters}
            handleChange={handleRecruiterChange}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
        <Box sx={{ width: 250, mr: 1 }}>
          <ExpenseTypeSearch types={expenseTypes} />
        </Box>
        <AmountRange />
        <PayPeriodRangeSelector
          onHandleChange={handleOnFilterDateChange}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          title="Range"
          startDate={expensesFilters.dateFrom}
          endDate={expensesFilters.dateTo}
          buttonPickerVariant="outlined"
          nullableComponent={true}
          maxDate={maxDateFilters}
        />
      </Box>
    </Box>
  );
};

export default ExpenseFilters;
