import React, { useEffect, useState } from "react";
import { TITLE_CHANGED } from "../../redux/actions/actionTypes";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import JobAssignmentApi from "../../api/JobAssignmentApi";
import JobAssignmentHeader from "./JobAssignmentHeader";
import TravelerHistoryTimecards from "./TravelerHistoryTimecards";
import { JobAssignmentSearchHistory } from "types/history/JobAssignmentCardHistory";
import { Box } from "@mui/system";
import { hasUnreadMessages as hasUnreadMessagesApi } from "api/MessagingApi";

type TravelerHistoryParams = {
  jobId: string;
};

const TravelerHistory = () => {
  const [jobAssignment, setJobAssignment] = useState<JobAssignmentSearchHistory>();
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  const dispatch = useDispatch();
  const { jobId } = useParams<TravelerHistoryParams>();

  const loadJobAssignment = async () => {
    try {
      const response = await JobAssignmentApi.getJobAssignmentById(jobId);
      setJobAssignment(response);

      if (response.jobAssignmentTimecardsHistory.length > 0) {
        try {
          const hasUnread = await hasUnreadMessagesApi(
            response.jobAssignmentTimecardsHistory[0].timesheetId
          );
          setHasUnreadMessages(hasUnread);
        } catch (error) {
          console.error({ error });
        }
      }
    } catch (error) {
      console.error({ error });
    }
  };

  const onMessagesRead = () => {
    setHasUnreadMessages(false);
  };

  useEffect(() => {
    loadJobAssignment();
  }, [jobId]);

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Traveler History" });
  }, []);

  return (
    <Box>
      {!!jobAssignment && (
        <>
          <Box sx={{ backgroundColor: "white" }}>
            <JobAssignmentHeader
              jobAssignment={jobAssignment}
              hasUnreadMessages={hasUnreadMessages}
              onMessagesRead={onMessagesRead}
            />
          </Box>
          <TravelerHistoryTimecards
            timecards={jobAssignment.jobAssignmentTimecardsHistory}
            refreshJobAssignment={loadJobAssignment}
          />
        </>
      )}
    </Box>
  );
};

export default TravelerHistory;
