import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker from "react-day-picker";
import { getWeekRange, getWeekDays, getPayPeriodEndDate } from "../functions/PayPeriodDateHelper";
import { Box } from "@mui/material";

export default class PayPeriodSelector extends React.Component {
  state = {
    hoverRange: undefined,
    selectedDays: this.props.selectedDays,
    isCurrentPeriod: false,
    disabledCurrentPayPeriod: this.props.disabledCurrentPayPeriod,
    showCalendar: this.props.showCalendar,
  };

  handleDayChange = (date, { selected, disabled }) => {
    if (disabled) {
      // Day is disabled, do nothing
      return;
    }

    this.setState({
      selectedDays: getWeekDays(moment(getWeekRange(date).from).format("MM/DD/YYYY")),
    });
    this.props.onChange(
      moment(getWeekRange(date).from).format("MM/DD/YYYY"),
      moment(getWeekRange(date).to).format("MM/DD/YYYY")
    );
  };

  handleDayEnter = (date) => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  handleWeekClick = (weekNumber, days, e) => {
    this.setState({
      selectedDays: days,
    });
  };

  render() {
    const { hoverRange, selectedDays } = this.state;

    const daysAreSelected = selectedDays.length > 0;

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const disabledDays = getPayPeriodEndDate()
      ? [
          (d) => {
            const payPeriodEndDate = moment(new Date(getPayPeriodEndDate())).startOf("day");
            return moment(d)
              .startOf("day")
              .isAfter(
                this.state.disabledCurrentPayPeriod
                  ? payPeriodEndDate.add(-7, "days")
                  : payPeriodEndDate
              );
          },
        ]
      : [true];

    return (
      <>
        <div>
          <h3 className="payperiod-title">
            PAY PERIOD:{" "}
            {this.props.showCalendar ? (
              <Box>
                <DayPicker
                  selectedDays={selectedDays}
                  onDayMouseEnter={this.handleDayEnter}
                  onDayMouseLeave={this.handleDayLeave}
                  onWeekClick={this.handleWeekClick}
                  disabledDays={disabledDays}
                  onDayClick={this.handleDayChange}></DayPicker>
              </Box>
            ) : (
              <DayPickerInput
                value={
                  moment(selectedDays[0]).format("MM/DD/YYYY") +
                  " to " +
                  moment(selectedDays[6]).format("MM/DD/YYYY")
                }
                onDayChange={this.handleDayChange}
                modifiers={modifiers}
                keepFocus={false}
                inputProps={{ style: { width: "20vh", textAlign: "center" } }}
                dayPickerProps={{
                  selectedDays: selectedDays,
                  onDayMouseEnter: this.handleDayEnter,
                  onDayMouseLeave: this.handleDayLeave,
                  onWeekClick: this.handleWeekClick,
                  disabledDays: disabledDays,
                }}
              />
            )}
          </h3>
        </div>
      </>
    );
  }
}

PayPeriodSelector.propTypes = {
  onChange: PropTypes.func,
  selectedDays: PropTypes.array,
  disabledCurrentPayPeriod: PropTypes.bool,
  showCalendar: PropTypes.bool,
};
