import React from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

type TextFieldsProps = {
  label: string;
  name: string;
  handleChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearValue: () => void;
  value: string;
};

type UserSearchProps = {
  handleSearch: () => void;
  searchByPayPeriod?: boolean;
  handleChangePayPeriod?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  textFieldsProps?: TextFieldsProps[];
};

export const UserSearch = ({
  handleSearch,
  searchByPayPeriod,
  handleChangePayPeriod,
  disabled,
  textFieldsProps,
}: UserSearchProps) => {
  return (
    <Box sx={{ padding: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
      {textFieldsProps?.map((textFieldProps, index) => (
        <TextField
          sx={{ width: 225, px: 1 }}
          key={`${textFieldProps.name}-${index}`}
          id={`${textFieldProps.name}-search`}
          label={textFieldProps.label}
          type="text"
          name={textFieldProps.name}
          variant="outlined"
          size="small"
          autoComplete="off"
          value={textFieldProps.value}
          onChange={textFieldProps.handleChangeValue}
          InputProps={{
            endAdornment: textFieldProps.value && (
              <IconButton onClick={textFieldProps.handleClearValue} size="small">
                <ClearIcon />
              </IconButton>
            ),
          }}
        />
      ))}
      {handleChangePayPeriod && (
        <FormGroup sx={{ py: 1 }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked onChange={handleChangePayPeriod} value={searchByPayPeriod} />
            }
            label="Current pay period"
          />
        </FormGroup>
      )}

      <Button variant="contained" sx={{ py: 1 }} onClick={handleSearch} disabled={disabled}>
        Search
      </Button>
    </Box>
  );
};
