import { msalApiFetch } from "commons/authConfig";
import { API_URL } from "commons/environment";
import { Contract } from "types/facility/contract";

const endpoint = "contracts";
const jsonContentType = "application/json";

export async function getActiveContracts() {
  const url = `${API_URL}/${endpoint}/active`;
  const data = await msalApiFetch(url, {
    method: "GET",
    headers: { "Content-Type": jsonContentType },
  });
  if (!data.ok) throw data;
  return data.json();
}

export async function getAvailableContracts(): Promise<Contract[]> {
  const url = `${API_URL}/${endpoint}/available`;
  const data = await msalApiFetch(url, {
    method: "GET",
    headers: { "Content-Type": jsonContentType },
  });
  if (!data.ok) throw data;
  return data.json();
}

export function getContractById(contractId: string) {
  const url = `${API_URL}/${endpoint}/${contractId}`;
  return msalApiFetch(url, {
    method: "GET",
    headers: { "Content-Type": jsonContentType },
  }).then((data) => {
    if (!data.ok) throw data;
    return data.json();
  });
}

export async function updateContract(contractId: string, contract: Contract) {
  const url = `${API_URL}/${endpoint}/${contractId}`;
  return msalApiFetch(url, {
    method: "PUT",
    headers: { "Content-Type": jsonContentType },
    body: JSON.stringify(contract),
  }).then((data) => {
    if (!data.ok) throw data;
  });
}

export async function createContract(contract: Contract): Promise<Contract> {
  const url = `${API_URL}/${endpoint}`;
  return msalApiFetch(url, {
    method: "POST",
    headers: { "Content-Type": jsonContentType },
    body: JSON.stringify(contract),
  }).then((data) => {
    if (!data.ok) throw data;
    return data.json();
  });
}
