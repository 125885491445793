import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { Fragment } from "react";
import CallOffsSectionRow from "./CallOffsSectionRow";

type CallOffsSectionProps = {
  callOffs: any[];
  onChange?: (callOffs: any[] | undefined) => void;
  isReadOnly?: boolean;
  isDigitalTimecardEntry: boolean;
};

const CallOffsSection = ({
  callOffs = [],
  onChange,
  isReadOnly = false,
  isDigitalTimecardEntry,
}: CallOffsSectionProps) => {
  const handleChange = (name: string, value: number | undefined) => {
    let newCallOffs = [];
    newCallOffs = callOffs.map((r) => {
      return r.name === name ? { ...r, hours: value, proratedSyncBroken: true } : r;
    });
    onChange && onChange(newCallOffs);
  };

  return (
    <Table
      sx={{
        minWidth: 650,
      }}
      className={" timecard-hours-entry-table"}
      size="small">
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell align="right">Hours</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {callOffs.map((row) => {
          return (
            <Fragment key={"Fragment" + row.name}>
              <CallOffsSectionRow
                key={row.name}
                row={row}
                isReadOnly={isReadOnly}
                handleCallOffChange={handleChange}
                isDigitalTimecardEntry={isDigitalTimecardEntry}
              />
            </Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default CallOffsSection;
