import { getRoles, msalInstance } from "./authConfig";
import { Agency, Roles } from "./Enums";

export default {
  logOutUser(): void {
    msalInstance
      .logoutRedirect({
        account: msalInstance.getActiveAccount(),
      })
      .catch((e) => console.error(e));
  },
  isRecruiter(): boolean {
    return getRoles(msalInstance.getActiveAccount()?.idTokenClaims?.roles).includes(
      Roles.Recruiter
    );
  },
  isAdmin(): boolean {
    return (
      !this.isRecruiter() &&
      getRoles(msalInstance.getActiveAccount()?.idTokenClaims?.roles).includes(Roles.Admin)
    );
  },
  isSuperAdmin(): boolean {
    const roles = getRoles(msalInstance.getActiveAccount()?.idTokenClaims?.roles);
    const selectedAgency = localStorage.getItem("selectedAgency");

    const isSuperAdmin =
      selectedAgency === Agency.Triage
        ? roles.includes(Roles.SuperAdmin)
        : roles.includes(Roles.TriagePlusSuperAdmin);

    return !this.isRecruiter() && isSuperAdmin;
  },
  isSalesRep(): boolean {
    return (
      !this.isRecruiter() &&
      getRoles(msalInstance.getActiveAccount()?.idTokenClaims?.roles).includes(Roles.SalesRep)
    );
  },
  isRecruiterTeamLead(): boolean {
    return getRoles(msalInstance.getActiveAccount()?.idTokenClaims?.roles).includes(
      Roles.RecruiterTeamLead
    );
  },
  isAssistant(): boolean {
    return getRoles(msalInstance.getActiveAccount()?.idTokenClaims?.roles).includes(
      Roles.Assistant
    );
  },
  getAudience(): string | undefined {
    return msalInstance.getActiveAccount()?.idTokenClaims?.aud;
  },
  getOid(): string | undefined {
    return msalInstance.getActiveAccount()?.idTokenClaims?.oid;
  },
};
