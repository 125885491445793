import React, { useEffect, useState } from "react";
import { getWeekRange } from "../shared/functions/PayPeriodDateHelper";
import moment from "moment";
import ExpensesApi from "api/ExpensesApi";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import {
  EXPENSE_FILTERS_CHANGED_RESET,
  EXPENSE_FILTERS_CHANGED_STATE,
  TITLE_CHANGED,
} from "../../redux/actions/actionTypes";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import { useAppDispatch } from "hooks";
import { Expense, OptionType } from "types/expenses/Expense";
import BPPTable, { Column } from "components/shared/components/bppTable/BPPTable";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/system";
import { ExpenseExceptionMessages, JobAssignmentStatus } from "commons/Enums";
import ExpenseFilters from "./filters/ExpenseFilters";
import { useSelector } from "react-redux";
import ExpenseFiltersRequest from "types/expenses/ExpenseFiltersRequest";
import { useSnackbar } from "notistack";
import LoggedUser from "commons/LoggedUser";
import { expenseEmptyRequest } from "redux/reducers/expenseTableReducer";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadBlob } from "commons/utils";

const DATE_FORMAT = "MM/DD/YYYY";

type ExpensesDataIds =
  | "jobAssignmentDataCandidateName"
  | "recruiterDataFullName"
  | "typeName"
  | "amount"
  | "payDate"
  | "stateName"
  | "actions";

type FlattenExpense = Expense & {
  jobAssignmentDataCandidateName: string;
  recruiterDataFullName: string;
};

type ExpensesData = FlattenExpense;

const Expenses = () => {
  const [timesheetStart, setTimesheetStart] = useState("");
  const [expenses, setExpenses] = useState<FlattenExpense[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [expenseState, setExpenseState] = useState<OptionType[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const isSuperAdmin = LoggedUser.isSuperAdmin();
  const isAdmin = LoggedUser.isAdmin();

  const history = useHistory();
  const dispatch = useAppDispatch();

  const currentPayPeriodDate = moment().add(-7, "days").format(DATE_FORMAT);

  const formatDate = (payDate: string | null) => {
    return payDate ? moment(payDate).format(DATE_FORMAT) : "";
  };

  const filters = useSelector((state: RootState) => state.expensesState);
  const headerStartDate = useSelector((state: RootState) => state.header.startDate);

  const isRequestEmpty = () => {
    return Object.entries(filters).every(([, value]) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else if (typeof value === "object" && value !== null) {
        return Object.keys(value).length === 0;
      } else {
        return value === "" || value === undefined || value === null;
      }
    });
  };

  const checkValidDates = () => {
    if (!filters.dateTo && !filters.dateFrom) {
      return true;
    }

    if (filters.dateTo && filters.dateFrom) {
      return moment(filters.dateTo).isValid() && moment(filters.dateFrom).isValid();
    }

    return false;
  };

  const getExpenses = async (emptyRequest = false) => {
    if (isRequestEmpty()) {
      enqueueSnackbar(`Please, add a filter.`, { variant: "error" });
      return;
    }

    if (!checkValidDates()) {
      enqueueSnackbar(`Please, add a valid date range.`, { variant: "error" });
      return;
    }

    setLoadingData(true);
    try {
      const requestFilters = emptyRequest ? expenseEmptyRequest : filters;

      const request: ExpenseFiltersRequest = {
        ...requestFilters,
        recruiters: requestFilters.recruiters?.recruiterIds ?? [],
        travelers: requestFilters.travelers?.travelerIds ?? [],
        expenseTypes: requestFilters.expenseTypes.map((t) => t.id),
        states: requestFilters.states
          .map((s) => {
            const stateName = s.toLowerCase();
            return expenseState.find((e) => {
              const expenseName = e.name.toLowerCase();
              const isAdminOrSuperAdmin = isSuperAdmin || isAdmin;
              const isNotDraft = expenseName !== "draft";
              return (
                expenseName === stateName &&
                (!isAdminOrSuperAdmin || (isAdminOrSuperAdmin && isNotDraft))
              );
            })?.id;
          })
          .filter((id): id is string => id !== undefined),
      };

      const expenses = await ExpensesApi.getExpenses(request);

      setExpenses(
        expenses.map((e: Expense) => {
          return {
            ...e,
            payDate: formatDate(e.payDate),
            // toUpperCase is used to order correctly
            jobAssignmentDataCandidateName: e.jobAssignmentData.candidateName.toUpperCase(),
            recruiterDataFullName: e.recruiterData.fullName.toUpperCase(),
            typeName: e.typeName ?? "",
            stateName: e.stateName ?? "",
          };
        })
      );
    } catch (er) {
      enqueueSnackbar(`Error while getting Expenses.`, { variant: "error" });
    } finally {
      setLoadingData(false);
    }
  };

  const getExpenseStatus = async () => {
    try {
      const expenses = await ExpensesApi.getExpenseStates();
      if (isSuperAdmin || isAdmin) {
        const draftIndex = expenses.expenseStates.findIndex((element) => element.name === "Draft");
        expenses.expenseStates.splice(draftIndex, 1);
      }
      setExpenseState(expenses.expenseStates);
    } catch {
      enqueueSnackbar(`Error while getting Expense Status.`, { variant: "error" });
    }
  };

  const resetFilters = () => {
    dispatch({ type: EXPENSE_FILTERS_CHANGED_RESET });
    getExpenses(true);
  };

  useEffect(() => {
    const weekRange = getWeekRange(currentPayPeriodDate);
    setTimesheetStart(moment(weekRange.from).format(DATE_FORMAT));
    getExpenseStatus();

    dispatch({ type: TITLE_CHANGED, title: "Expenses" });

    if (isSuperAdmin || isAdmin) {
      const draftIndex = filters.states.findIndex((element) => element === "Draft");
      if (draftIndex >= 0) {
        filters.states.splice(draftIndex, 1);
        dispatch({ type: EXPENSE_FILTERS_CHANGED_STATE, states: filters.states });
      }
    }
  }, []);

  useEffect(() => {
    if (expenseState.length > 0) getExpenses();
  }, [expenseState]);

  const downloadAllExpenseFiles = async (expense: FlattenExpense) => {
    try {
      const response = await ExpensesApi.downloadExpenseFiles(expense.id);
      downloadBlob(
        response,
        `${expense.jobAssignmentData.candidateName}, ${expense.payDate?.replaceAll("/", "-")}.pdf`
      );
    } catch {
      enqueueSnackbar(`Error while downloading all expense files.`, { variant: "error" });
    }
  };

  const columns: Column<ExpensesDataIds, ExpensesData>[] = [
    {
      id: "jobAssignmentDataCandidateName",
      label: "Traveler",
      width: "20%",
      format(value, rowData) {
        const isPayDateBeforeCurrentPeriod = rowData.payDate
          ? moment(new Date(rowData.payDate), DATE_FORMAT).isBefore(
              moment(new Date(headerStartDate), DATE_FORMAT)
            )
          : false;

        const showTooltip =
          (moment(rowData.payDate).isBefore(moment(timesheetStart, DATE_FORMAT)) &&
            rowData.timeSheetId == null) ||
          (rowData.jobAssignmentData?.status === JobAssignmentStatus.CANCELLED &&
            rowData.timeSheetId == null);

        return (
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Link
              to={`/expenseDetails/${rowData.id}`}
              target={isPayDateBeforeCurrentPeriod ? "_blank" : "_self"}
              rel="noopener noreferrer">
              <Typography sx={{ textTransform: "capitalize" }}>
                {(value as string).toLowerCase()}
              </Typography>
            </Link>
            {showTooltip && (
              <Tooltip
                title={
                  rowData?.jobAssignmentData?.status === JobAssignmentStatus.CANCELLED
                    ? ExpenseExceptionMessages.JobCancelledMessage
                    : ExpenseExceptionMessages.PayDateMessage
                }
                sx={{ marginLeft: 1 }}>
                <InfoOutlinedIcon color="primary" />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      id: "recruiterDataFullName",
      label: "Recruiter",
      width: "20%",
      format(value) {
        return (
          <Typography fontSize={14} sx={{ textTransform: "capitalize" }}>
            {(value as string).toLowerCase()}
          </Typography>
        );
      },
    },
    {
      id: "typeName",
      label: "Expense Type",
      format(value) {
        return (
          <Typography fontSize={14} sx={{ textTransform: "capitalize" }}>
            {value as string}
          </Typography>
        );
      },
    },
    {
      id: "amount",
      label: "Amount",
      format(value) {
        return (
          <Typography fontSize={14} sx={{ textTransform: "capitalize" }}>
            ${(value as number)?.toFixed(2)}
          </Typography>
        );
      },
    },
    {
      id: "payDate",
      label: "Pay Date",
      comparator: "date",
      format(value) {
        return (
          <Typography fontSize={14} sx={{ textTransform: "capitalize" }}>
            {(value as string).toLowerCase()}
          </Typography>
        );
      },
    },
    {
      id: "stateName",
      label: "Status",
      format(value) {
        return (
          <Typography fontSize={14} sx={{ textTransform: "capitalize" }}>
            {(value as string).toLowerCase()}
          </Typography>
        );
      },
    },
    {
      id: "actions",
      label: "Actions",
      exportable: false,
      format(value, data) {
        if (data.hasFiles) {
          return (
            <IconButton color="primary" onClick={() => downloadAllExpenseFiles(data)}>
              <DownloadIcon />
            </IconButton>
          );
        }

        return <></>;
      },
    },
  ];

  return (
    <Box sx={{ margin: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <ExpenseFilters expenseStatus={expenseState} />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Button sx={{ m: 1.2 }} variant="contained" onClick={() => getExpenses()}>
            Search expenses
          </Button>
        </Box>
      </Box>
      <BPPTable<ExpensesData, ExpensesDataIds>
        columns={columns}
        data={expenses}
        loading={loadingData}
        showFilters={false}
        handleClear={resetFilters}
        exportable
        exportFileName="expenses"
        exportLimit={5000}
        customSearch={
          <CanRenderChildren permissionName="canCreateExpenses">
            <Button
              sx={{ m: 1.2 }}
              variant="contained"
              onClick={() => history.push("/expenseDetails")}>
              Create Expense
            </Button>
          </CanRenderChildren>
        }
        maxHeight="calc(100vh - 270px)"
        clearButtonTitle="Reset Filters"
      />
    </Box>
  );
};

export default Expenses;
