import React from "react";
import { Avatar, Box, Chip, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type TimecardDetailTopbarCallOffsProps = {
  hospitalCallOffs?: number;
  personalCallOffs?: number;
  sickCallOffs?: number;
  otherCallOffs?: number;
  showInfo?: boolean;
};

const TimecardDetailTopbarCallOffs = ({
  hospitalCallOffs = 0,
  otherCallOffs = 0,
  personalCallOffs = 0,
  sickCallOffs = 0,
  showInfo = false,
}: TimecardDetailTopbarCallOffsProps) => {
  return (
    <Box my={1} display={"flex"} flexDirection={"row"} gap={1}>
      <Chip
        avatar={<Avatar sx={{ px: 0.4 }}>{hospitalCallOffs.toFixed(2)}</Avatar>}
        label="Hospital"
        size="small"
      />
      <Chip
        avatar={<Avatar sx={{ px: 0.4 }}>{personalCallOffs.toFixed(2)}</Avatar>}
        label="Personal"
        size="small"
      />
      <Chip
        avatar={<Avatar sx={{ px: 0.4 }}>{sickCallOffs.toFixed(2)}</Avatar>}
        label="Sick"
        size="small"
      />
      <Chip
        avatar={<Avatar sx={{ px: 0.4 }}>{otherCallOffs.toFixed(2)}</Avatar>}
        label="Other"
        size="small"
      />
      {showInfo && (
        <Tooltip title="Call off totals will update when approved" placement="top" sx={{ m: 0 }}>
          <InfoOutlinedIcon />
        </Tooltip>
      )}
    </Box>
  );
};

export default TimecardDetailTopbarCallOffs;
