import React, { Component } from "react";
import TimecardTile from "./TimecardTile";
import Collapse from "@mui/material/Collapse";
import { TransitionGroup } from "react-transition-group";
import SearchBar from "components/shared/components/SearchBar";
import { Scrollbars } from "components/shared/components/scrollBars";

type TimecardsProps = {
  assignments: any[];
  scrollIntoId: number;
  emptyAssignmentsMessage: string;
  selectedAssignmentId: string;
  onTimecardSelected: (id: string) => void;
  fetchAssignments: () => void;
};

type StateProps = {
  assignments: any[];
  value: string;
};

export default class Timecards extends Component<TimecardsProps, StateProps> {
  private scrollBar: Scrollbars | null = null;

  constructor(props: TimecardsProps) {
    super(props);
    this.state = {
      assignments: this.props.assignments,
      value: "",
    };
    this.scrollToAssignment();
  }

  scrollToAssignment() {
    if (this.props.scrollIntoId) {
      const index = this.props.assignments.findIndex((a) => a.id === this.props.scrollIntoId);

      if (index > 0)
        setTimeout(() => {
          this.scrollBar?.scrollTop(index * 121 - 5);
        }, 200);
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.scrollIntoId !== prevProps.scrollIntoId) {
      this.scrollToAssignment();
    }

    if (this.props.assignments !== prevProps.assignments) {
      this.setNewAssignments();
    }
  }

  setNewAssignments() {
    this.setState({ assignments: this.props.assignments });
  }

  search(valueToFilter: any) {
    const filteredAssignments = this.props.assignments.filter((user) =>
      user.candidateFullName.toLowerCase().includes(valueToFilter.toLowerCase())
    );

    this.setState({ assignments: filteredAssignments });
    this.setState({ value: valueToFilter });
  }

  render() {
    const props = this.props;
    if (!props.assignments || !props.assignments.length)
      return <div className="timecard-list-empty">{props.emptyAssignmentsMessage}</div>;
    return (
      <>
        <div style={{ paddingBottom: "10px", paddingRight: "16px" }}>
          <SearchBar
            value={this.state.value}
            onChange={(newValue) => this.search(newValue)}
            onCancelSearch={() => this.search("")}
            placeholder="Search by name..."
          />
        </div>
        <Scrollbars
          autoHide
          ref={(e) => (this.scrollBar = e)}
          style={{ height: "calc(100vh - 190px)" }}>
          <TransitionGroup className="timecards-list">
            {this.state.assignments.map((a) => (
              <Collapse key={a.id}>
                <div>
                  <TimecardTile
                    onClick={() => props.onTimecardSelected(a.id)}
                    timesheet={a}
                    selected={a.id === props.selectedAssignmentId}
                    fetchAssignments={this.props.fetchAssignments}
                  />
                </div>
              </Collapse>
            ))}
          </TransitionGroup>
        </Scrollbars>
      </>
    );
  }
}
