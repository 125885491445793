import { Box, Button, Typography } from "@mui/material";
import React from "react";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";

type TimecardDetailContentLateProps = {
  onUndoLateTimecard: () => void;
};

const TimecardDetailContentLate = ({ onUndoLateTimecard }: TimecardDetailContentLateProps) => {
  return (
    <Box pt={6}>
      <Typography>
        This is a late timecard. You can process this timecard once the main payroll has been
        locked.
      </Typography>

      <CanRenderChildren permissionName="undoLateTimecard">
        <Box textAlign={"center"} mt={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onUndoLateTimecard}
            endIcon={<RotateLeftOutlinedIcon />}>
            Undo Late
          </Button>
        </Box>
      </CanRenderChildren>
    </Box>
  );
};

export default TimecardDetailContentLate;
