import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import {
  Button,
  Select,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Popper,
  Paper,
  Box,
  SelectChangeEvent,
  Alert,
} from "@mui/material";
import {
  ArrowBack,
  CalendarToday,
  Save,
  Send,
  Delete,
  AssignmentReturn,
} from "@mui/icons-material";
import ApproveIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import DayPicker from "react-day-picker/DayPicker";
import { useSnackbar } from "notistack";
import ExpensesApi from "../../api/ExpensesApi";
import RecruitersApi from "../../api/RecruitersApi";
import NotesSection from "../notes/NotesSection";
import ExpenseJobAssignmentSelector from "./ExpenseJobAssignmentSelector";
import ExpenseFile from "./ExpenseFile";
import LoggedUser from "../../commons/LoggedUser";
import ConfirmDialog from "../shared/components/ConfirmDialog";
import {
  ExpenseStatus,
  RoleCodes,
  ExpenseExceptionMessages,
  JobAssignmentStatus,
} from "../../commons/Enums";
import { getWeekRange } from "../shared/functions/PayPeriodDateHelper";
import AlertDialog from "../shared/components/AlertDialog";
import { TITLE_CHANGED } from "../../redux/actions/actionTypes";
import { useDispatch } from "react-redux";
import { getFileType, parseErrorResponse } from "commons/utils";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import { DayModifiers } from "react-day-picker";
import { OptionType } from "types/expenses/Expense";
import { LoadingButton } from "@mui/lab";
import { FileDragAndDrop } from "./ExpenseFileDragAndDrop";

const ERROR_SAVING = "An error occurred while saving changes.";
const DATE_FORMAT_US = "MM/DD/YYYY";
const EXPENSE_STATE_MISSING = "Expense states are missing, please reload the page.";

type Expense = {
  id?: string;
  timeSheetId?: string;
  stateId: string;
  notes: any[];
  files: { id?: string; fileName: string }[];
  recruiterId: string;
  typeId: string | null;
  amount?: string | null;
  payDate?: string | null;
  createdByIdRole?: number;
  recruiterData?: {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  jobAssignmentData?: {
    candidateName: string;
    clientLocation: string;
    id: string;
    period: string;
    status?: string;
    laborEdgeJobId?: string;
    clientName?: string;
  };
};

type Recruiter = {
  id: string;
  fullName: string;
};

type ExpenseOptionsAllowedActions = {
  state: {
    id: string;
  };
  canSave: boolean;
  canSubmit: boolean;
  canDelete: boolean;
  canReject: boolean;
  canApprove: boolean;
};

type ExpenseOptions = {
  allowedActions?: ExpenseOptionsAllowedActions[];
  startPayDate?: string;
  maxAmount?: number;
};

const fileUploadLimit = 5;

const getDisabledReason = (expense: Expense, files: string[]) => {
  if (!expense.id) {
    return "Please save the expense before uploading files.";
  }

  if (files.length >= fileUploadLimit) {
    return "Max files allowed: 5. Remove or update existing files.";
  }

  return "";
};

const acceptedFiles = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "application/pdf",
  "image/heic",
  "image/tiff",
];

const ExpenseDetails = () => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const isSuperAdmin = LoggedUser.isSuperAdmin();
  const isAdmin = LoggedUser.isAdmin();
  const isSalesRep = LoggedUser.isSalesRep();

  const initialStateId = "3dceb789-dc20-4e24-ba7f-198ec7926a23";
  const currentPayPeriodDate = moment().add(-7, "days").format(DATE_FORMAT_US);
  const [uploadFileIds, setUploadFileIds] = useState<string[]>([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [approveDisabled, setApproveDisabled] = useState(true);
  const [rejectDisabled, setRejectDisabled] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [expense, setExpense] = useState<Expense>({
    notes: [],
    files: [],
    recruiterId: "",
    typeId: null,
    stateId: initialStateId,
  });
  const [newNote, setNote] = useState("");
  const [openTravelerDialog, setOpenTravelerDialog] = useState(false);
  const [recruitersList, setRecruitersList] = useState<Recruiter[]>([]);
  const { expenseId } = useParams<{ expenseId: string }>();
  const [expenseOptions, setExpenseOptions] = useState<ExpenseOptions>({});
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState<OptionType[]>([]);
  const [expenseStates, setExpenseStates] = useState(new Map());
  const [timesheetStart, setTimesheetStart] = useState<string | null>(null);
  const [jobAssignmentEndDate, setJobAssignmentEndDate] = useState<string | null>(null);
  const [isExpensePayDateDialogOpen, setIsExpensePayDateDialogOpen] = React.useState(false);
  const isReadOnly =
    isSalesRep ||
    ((expense.stateId === expenseStates.get(ExpenseStatus.IN_REVIEW) ||
      expense.stateId === expenseStates.get(ExpenseStatus.APPROVED)) &&
      !isAdmin &&
      !isSuperAdmin) ||
    expense.stateId === expenseStates.get(ExpenseStatus.PAID) ||
    expense.stateId === expenseStates.get(ExpenseStatus.TO_BE_PAID);

  const blockNoteSection =
    (isReadOnly &&
      !isSuperAdmin &&
      expense.stateId !== expenseStates.get(ExpenseStatus.TO_BE_PAID)) ||
    expense.stateId === expenseStates.get(ExpenseStatus.PAID);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [expenseCanBeDeleted, setExpenseCanBeDeleted] = useState(false);
  const [expenseCanBeRejected, setExpenseCanBeRejected] = useState(false);
  const [expenseCanBeApproved, setExpenseCanBeApproved] = useState(false);
  const [expenseCanBeSaved, setExpenseCanBeSaved] = useState(false);
  const [expenseCanBeSubmitted, setExpenseCanBeSubmitted] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [isPossibleDuplicate, setIsPossibleDuplicate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const weekRange = getWeekRange(currentPayPeriodDate);
    setTimesheetStart(moment(weekRange.from).format(DATE_FORMAT_US));
  }, [currentPayPeriodDate]);

  useEffect(() => {
    getFiles();
  }, [expense.id]);

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Expense Details" });

    const initExpense = async () => {
      await getExpenseOptions(expenseId);

      const resultExpenseTypes = await ExpensesApi.getExpenseTypes();
      setExpenseTypes(resultExpenseTypes.expenseTypes);

      try {
        let tempStatesMap = new Map();
        const expenseStatesResponse = await ExpensesApi.getExpenseStates();
        expenseStatesResponse.expenseStates.forEach((state: OptionType) => {
          tempStatesMap = new Map(tempStatesMap.set(state.name, state.id));
        });
        setExpenseStates(tempStatesMap);
      } catch (error) {
        console.error(error);
      }

      try {
        if (!isSalesRep) {
          const recruitersResponse = await RecruitersApi.getRecruiters();

          if (recruitersResponse.length === 1 && !expenseId) {
            setExpense(
              Object.assign({}, expense, {
                payDate: null,
                amount: null,
                typeId: null,
                jobAssignmentData: null,
                recruiterId: recruitersResponse[0].id,
                recruiterData: {
                  id: recruitersResponse[0].id,
                  fullName: recruitersResponse[0].fullName,
                },
              })
            );
          }

          setRecruitersList(recruitersResponse);
        }

        if (expenseId) {
          const expenseResponse = await ExpensesApi.getExpenseById(expenseId);
          setRecruitersList([expenseResponse.recruiterData]);

          if (expenseResponse.hasPossibleDuplicate) {
            setIsPossibleDuplicate(true);
          }

          const assignmentStartDate = moment(
            expenseResponse.jobAssignmentData.period.split(" to ")[0],
            "MM-DD-YYYY"
          ).format("YYYY-MM-DD");
          await getExpenseOptions(expenseId, assignmentStartDate);

          setExpense(expenseResponse);
          setAssignmentEndDate(expenseResponse.jobAssignmentData.period);
        }
      } catch (error) {
        console.error(error);
      }
    };

    initExpense().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (uploadFileIds.length !== 0 && expense.amount && expense.payDate && expense.typeId) {
      setApproveDisabled(false);
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
      setApproveDisabled(true);
    }
    if (
      expense.id &&
      expense.stateId !== expenseStates.get(ExpenseStatus.TO_BE_PAID) &&
      (expense.createdByIdRole === RoleCodes.Admin ||
        expense.createdByIdRole === RoleCodes.SuperAdmin)
    ) {
      setRejectDisabled(true);
    }
  }, [expense, uploadFileIds]);

  useEffect(() => {
    const allowedActions = {
      canSave: false,
      canSubmit: false,
      canDelete: false,
      canApprove: false,
      canReject: false,
    };

    if (expenseOptions.allowedActions) {
      const allowedActionInState = expenseOptions.allowedActions.find(
        (aa) => aa.state.id === expense.stateId
      );

      if (allowedActionInState) {
        allowedActions.canDelete = allowedActionInState.canDelete;
        allowedActions.canReject = allowedActionInState.canReject;
        allowedActions.canApprove = allowedActionInState.canApprove;
        allowedActions.canSave = allowedActionInState.canSave;
        allowedActions.canSubmit = allowedActionInState.canSubmit;
      }
    }

    setExpenseCanBeSaved(allowedActions.canSave);
    setExpenseCanBeSubmitted(allowedActions.canSubmit);
    setExpenseCanBeDeleted(allowedActions.canDelete);
    setExpenseCanBeApproved(allowedActions.canApprove);
    setExpenseCanBeRejected(allowedActions.canReject);
  }, [expense, expenseOptions]);

  /**
   * @param {string} [expenseId]
   * @param {string} [assignmentStartDate]
   */
  const getExpenseOptions = async (expenseId: string, assignmentStartDate?: string) => {
    const options = await ExpensesApi.getExpenseOptions(
      expenseId,
      assignmentStartDate ? moment(assignmentStartDate).format("YYYY-MM-DD") : undefined
    );

    setExpenseOptions(options);
    const jobAssignmentStartDate = new Date(assignmentStartDate ?? "");
    const startPayDate = new Date(options.startPayDate);

    if (jobAssignmentStartDate > new Date()) {
      const endDate = moment(startPayDate).add(6, "months").toDate();
      setMinDate(startPayDate);
      setMaxDate(endDate);
    } else {
      setMinDate(startPayDate);
      const endDate = moment().endOf("day").add(6, "months").toDate();
      setMaxDate(endDate);
    }
  };

  const saveExpenseFiles = async (newUploadFileIds: string[]) => {
    if (newUploadFileIds.length > 0) {
      const newFiles = [];
      for (const fileId of newUploadFileIds) {
        newFiles.push({ fileName: fileId });
      }
      expense.files = newFiles;
      const updatedExpense = { ...expense };
      setExpense(updatedExpense);
    }
    try {
      const result = await ExpensesApi.updateExpenseData(expense.id!, expense);
      setExpense(result);
      snackbar.enqueueSnackbar("Changes saved successfully.");
    } catch (error: any) {
      const errorMessage = await parseErrorResponse(error, ERROR_SAVING);
      snackbar.enqueueSnackbar(errorMessage);
    }
  };

  const uploadExpenseFile = async (files: FileList | null) => {
    if (files?.length === 0) return;
    if (
      (files?.length ?? 0) + uploadFileIds.length > fileUploadLimit ||
      (files?.length ?? 0) > fileUploadLimit
    ) {
      snackbar.enqueueSnackbar(
        `Max upload limit is ${fileUploadLimit}. You can only upload up to ${fileUploadLimit} files.`
      );
      return;
    }
    let newUploadFileId = "";

    if (files) {
      for (const file of files) {
        const currentFileType = getFileType(file);

        if (acceptedFiles.filter((format) => format === currentFileType).length < 1) {
          snackbar.enqueueSnackbar(
            "One of your files is not allowed. Select an image or pdf file."
          );
          return;
        }
      }

      for (const file of files) {
        try {
          newUploadFileId = await ExpensesApi.fileUpload(file, expense.id!);
          setUploadFileIds((s) => [...s, newUploadFileId]);
          snackbar.enqueueSnackbar("File uploaded successfully.");
        } catch (error: any) {
          const errorMessage = await parseErrorResponse(
            error,
            "An error occurred while uploading the file."
          );
          snackbar.enqueueSnackbar(errorMessage);
        }
      }
    }
  };

  const deleteExpenseFile = async (uploadFileId: string) => {
    if (!uploadFileIds.length) return;
    try {
      await ExpensesApi.fileDelete(uploadFileId);
      setUploadFileIds(uploadFileIds.filter((id) => id !== uploadFileId));
      await saveExpenseFiles(uploadFileIds.filter((id) => id !== uploadFileId));
    } catch {
      snackbar.enqueueSnackbar("An error occurred while deleting the file.");
    }
  };

  const updateExpenseFile = async (files: FileList, oldFileId: string) => {
    if (files.length === 0) return;
    for (const file of files) {
      const currentFileType = getFileType(file);

      if (acceptedFiles.filter((format) => format === currentFileType).length < 1) {
        snackbar.enqueueSnackbar("File type not allowed. Select an image or pdf file.");
        return;
      }
    }

    try {
      const result = await ExpensesApi.fileUpdate(files[0], oldFileId);
      const newArr = [...uploadFileIds];
      const index = uploadFileIds.indexOf(oldFileId);
      newArr[index] = result;
      setUploadFileIds(newArr);
      await saveExpenseFiles(newArr);
    } catch {
      snackbar.enqueueSnackbar("An error occurred while updating the file.");
    }
  };

  const onRecruiterChange = (recruiterAssociatedEvent: SelectChangeEvent<string>) => {
    setExpense(
      Object.assign({}, expense, {
        payDate: null,
        amount: null,
        typeId: null,
        jobAssignmentData: null,
        recruiterId: recruiterAssociatedEvent.target.value,
        recruiterData: {
          id: recruiterAssociatedEvent.target.value,
          fullName: "",
        },
      })
    );

    setSaveDisabled(true);
  };

  const onExpenseTypeChange = (e: SelectChangeEvent<string>) => {
    setExpense(Object.assign({}, expense, { typeId: e.target.value }));
    setSaveDisabled(!expense.recruiterId || !expense.jobAssignmentData);
  };

  const getFiles = async () => {
    if (expense.files.length > 0) {
      const newArr = expense.files.map((file) => file.fileName);
      setUploadFileIds(newArr);
    } else if (expense.id) {
      try {
        const result = await ExpensesApi.getExpenseFiles(expense.id);
        const newArr = result.map((file: any) => file.fileName);
        setUploadFileIds(newArr);
      } catch {
        snackbar.enqueueSnackbar("An error occurred while fetching the files.");
      }
    }
  };

  const onNoteChange = (note: string) => {
    setNote(note);
    if (expenseId) {
      if (note) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
    }
  };

  const handleDelete = () => {
    openConfirmDialog();
  };

  /**
   * @param {string} noteContent
   */
  const addNoteToExpense = (noteContent: string) => {
    const newNotes = [...expense.notes, { contents: noteContent }];
    expense.notes = newNotes;
    const updatedExpense = { ...expense };
    setExpense(updatedExpense);
  };

  const addFilesToExpense = () => {
    if (uploadFileIds) {
      const newFiles = [];
      for (const fileId of uploadFileIds) {
        newFiles.push({ fileName: fileId });
      }

      expense.files = newFiles;
      const updatedExpense = { ...expense };
      setExpense(updatedExpense);
    }
  };

  const addStateToExpense = (newState: string) => {
    expense.stateId = expenseStates.get(newState);

    const updatedExpense = { ...expense };
    setExpense(updatedExpense);
  };

  const getErrorMessage = async (error: any) => {
    let errorResponse;
    try {
      errorResponse = await error.json();
    } catch (error: any) {
      errorResponse = await error.text();
    }

    if (Array.isArray(errorResponse)) {
      return errorResponse[0];
    } else {
      return errorResponse;
    }
  };

  const updateExpenseData = (message: string, errorMessage: string, disableSubmit = true) => {
    return ExpensesApi.updateExpenseData(expense.id!, expense)
      .then(async (result) => {
        setExpense(result);
        setNote("");
        snackbar.enqueueSnackbar(message);
        setSaveDisabled(true);
        setSubmitDisabled(disableSubmit);

        setIsPossibleDuplicate(result.hasPossibleDuplicate);
      })
      .catch((error) => {
        console.error(error);
        if (error.status === 400) {
          getErrorMessage(error)
            .then((message) => {
              snackbar.enqueueSnackbar(message);
            })
            .catch((e) => console.error(e));
        } else {
          snackbar.enqueueSnackbar(errorMessage);
        }
      });
  };

  const openExpensePayDateDialog = () => {
    setIsExpensePayDateDialogOpen(true);
  };

  const closeExpensePayDateDialog = () => {
    setIsExpensePayDateDialogOpen(false);
  };

  const handleSave = () => {
    if (saveDisabled) return;
    setSaveLoading(true);

    if (newNote) {
      addNoteToExpense(newNote);
    } else if (!newNote && expense.stateId === expenseStates.get(ExpenseStatus.APPROVED)) {
      snackbar.enqueueSnackbar("Please add a note before saving an approved expense");
      setSaveLoading(false);
      return;
    }
    addFilesToExpense();

    if (expenseStates.size > 0) {
      if (isAdmin || isSuperAdmin) {
        if (
          ![
            expenseStates.get(ExpenseStatus.APPROVED),
            expenseStates.get(ExpenseStatus.REJECTED),
          ].includes(expense.stateId)
        ) {
          addStateToExpense(ExpenseStatus.IN_REVIEW);
        }
      } else {
        if (expense.stateId !== expenseStates.get(ExpenseStatus.REJECTED))
          addStateToExpense(ExpenseStatus.DRAFT);
      }
    } else {
      snackbar.enqueueSnackbar(EXPENSE_STATE_MISSING);
      setSaveLoading(false);
      return;
    }

    if (!expense.id) {
      ExpensesApi.addExpense(expense)
        .then(async (result) => {
          setExpense(result);
          setNote("");
          snackbar.enqueueSnackbar("Changes saved");
          setSaveDisabled(true);
          setIsPossibleDuplicate(result.hasPossibleDuplicate);
        })
        .catch((error) => {
          console.error(error);
          snackbar.enqueueSnackbar(ERROR_SAVING);
        })
        .finally(() => {
          setSaveLoading(false);
        });
    } else {
      updateExpenseData("Changes saved", ERROR_SAVING, false)
        .catch((e) => console.error(e))
        .finally(() => {
          setSaveLoading(false);
        });
    }
  };

  const handleSubmit = () => {
    if (submitDisabled) return;
    setSubmitLoading(true);

    if (newNote) {
      addNoteToExpense(newNote);
    }

    addNoteToExpense("Submitted expense for approval");

    addFilesToExpense();

    if (expenseStates.size > 0) {
      if (isAdmin || isSuperAdmin) {
        addStateToExpense(ExpenseStatus.APPROVED);
      } else {
        addStateToExpense(ExpenseStatus.IN_REVIEW);
      }
    } else {
      snackbar.enqueueSnackbar(EXPENSE_STATE_MISSING);
      setSubmitLoading(false);
      return;
    }

    updateExpenseData("Expense submitted", ERROR_SAVING)
      .catch((e) => console.error(e))
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleApprove = () => {
    if (moment(expense.payDate).isBefore(moment(timesheetStart, DATE_FORMAT_US))) {
      snackbar.enqueueSnackbar("Please update pay date, current pay date has passed.");
      return;
    }

    setApproveLoading(true);
    if (newNote) {
      addNoteToExpense(newNote);
    }
    addNoteToExpense("This expense has been approved");

    addFilesToExpense();

    if (expenseStates.size > 0) {
      addStateToExpense(ExpenseStatus.APPROVED);
    } else {
      snackbar.enqueueSnackbar(EXPENSE_STATE_MISSING);
      setApproveLoading(false);
      return;
    }
    updateExpenseData("Expense approved", "An error occurred while approving expense.")
      .catch((e) => console.error(e))
      .finally(() => {
        setApproveLoading(false);
      });
  };

  const handleReject = () => {
    setRejectLoading(true);
    if (newNote) {
      addNoteToExpense(newNote);
    } else {
      snackbar.enqueueSnackbar("Please add a note before rejecting.");
      setRejectLoading(false);
      return;
    }

    addFilesToExpense();

    if (expenseStates.size > 0) {
      addStateToExpense(ExpenseStatus.REJECTED);
    } else {
      snackbar.enqueueSnackbar(EXPENSE_STATE_MISSING);
      setRejectLoading(false);
      return;
    }
    updateExpenseData("Expense rejected", "An error occurred while rejecting expense.")
      .catch((e) => console.error(e))
      .finally(() => {
        setRejectLoading(false);
      });
  };

  const onSelectJobAssignment = () => {
    if (!expense.id && expense.recruiterId) {
      setOpenTravelerDialog(true);
    }
  };

  const setAssignmentEndDate = (period: string) => {
    const str = period.substring(period.indexOf("o") + 1);
    const endDate = moment(str, "MM-DD-YYYY").toDate();
    setJobAssignmentEndDate(moment(endDate).format(DATE_FORMAT_US));
  };

  const handleSelectedAssignment = async (assignment: any) => {
    setOpenTravelerDialog(false);
    setJobAssignmentEndDate(moment(assignment.endDate).format(DATE_FORMAT_US));
    setExpense({
      ...expense,
      jobAssignmentData: {
        id: assignment.id,
        laborEdgeJobId: assignment.laborEdgeJobId,
        period: `${moment(assignment.startDate).format(DATE_FORMAT_US)} to ${moment(
          assignment.endDate
        ).format(DATE_FORMAT_US)}`,
        candidateName: assignment.candidateName,
        clientName: assignment.clientName,
        clientLocation: `${assignment.clientCity}, ${assignment.clientState}`,
      },
      payDate: null,
      amount: null,
      typeId: null,
    });

    await getExpenseOptions(expenseId, assignment.startDate);

    setSaveDisabled(false);
  };

  const payDateAndAmountEditable = expense.recruiterId && expense.jobAssignmentData;

  const payDateDisabledDays = expenseOptions.startPayDate
    ? [
        (d: Date) =>
          d.getDay() !== 5 || d < new Date(expenseOptions.startPayDate ?? "") || d > maxDate,
      ]
    : [() => true];

  const openDatePicker = (event: any) => {
    if (payDateAndAmountEditable) {
      setDatePickerAnchor(event.currentTarget);
      setDatePickerOpen(true);
    }
  };

  const payDateSelected = (day: Date, { _, disabled }: DayModifiers) => {
    if (disabled) {
      // Day is disabled, do nothing
      return;
    }
    if (
      expense.jobAssignmentData?.status !== JobAssignmentStatus.CANCELLED &&
      moment(day).isAfter(moment(jobAssignmentEndDate, DATE_FORMAT_US))
    ) {
      openExpensePayDateDialog();
    }
    setExpense(Object.assign({}, expense, { payDate: day }));
    setDatePickerOpen(false);
    setSaveDisabled(!expense.recruiterId || !expense.jobAssignmentData);
  };

  const handleAmountChange = (value: NumberFormatValues) => {
    setExpense(Object.assign({}, expense, { amount: value.floatValue }));
    setSaveDisabled(
      !expense.recruiterId || !expense.jobAssignmentData || value.floatValue === expense.amount
    );
  };

  const isAmountAllowed = ({ floatValue }: NumberFormatValues) => {
    if (!floatValue) return true;

    return expenseOptions.maxAmount ? floatValue <= expenseOptions.maxAmount : false;
  };

  const openConfirmDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const onConfirmExpenseDelete = () => {
    setDeleteLoading(true);
    ExpensesApi.deleteExpense(expense.id!)
      .then(() => {
        snackbar.enqueueSnackbar("The expense has been deleted");
        history.push("/expenses");
      })
      .catch((error) => {
        console.error(error);
        snackbar.enqueueSnackbar("An error occurred while deleting the expense");
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <div className="view-container">
      <form className="component-form" noValidate autoComplete="off">
        {((moment(expense.payDate).isBefore(moment(timesheetStart, DATE_FORMAT_US)) &&
          expense.timeSheetId == null) ||
          (expense.jobAssignmentData?.status === JobAssignmentStatus.CANCELLED &&
            expense.timeSheetId == null)) && (
          <Box display="flex">
            <Alert severity="warning">
              {expense.jobAssignmentData?.status === JobAssignmentStatus.CANCELLED
                ? ExpenseExceptionMessages.JobCancelledMessage
                : ExpenseExceptionMessages.PayDateMessage}
            </Alert>
          </Box>
        )}
        <Grid container spacing={2}>
          {isPossibleDuplicate && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <Alert severity="warning">
                    This expense might be a possible duplicate, please review it.
                  </Alert>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={6} style={{ marginBottom: 16 }}>
            <FormControl variant="outlined" fullWidth style={{ marginBottom: 16 }}>
              <InputLabel id="recruiter">Recruiter</InputLabel>
              <Select
                labelId="recruiter-label"
                id="recruiter"
                label="Recruiter"
                fullWidth
                type="recruiter"
                name="Recruiter"
                value={expense.recruiterId}
                onChange={onRecruiterChange}
                inputProps={{ readOnly: !!expense?.id }}>
                {recruitersList.length
                  ? recruitersList.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.fullName}
                      </MenuItem>
                    ))
                  : expense.recruiterData && (
                      <MenuItem key={expense.recruiterData.id} value={expense.recruiterData.id}>
                        {expense.recruiterData.fullName}
                      </MenuItem>
                    )}
              </Select>
            </FormControl>
            <TextField
              id="traveler"
              name="traveler"
              label="Traveler"
              type="traveler"
              variant="outlined"
              onClick={onSelectJobAssignment}
              value={expense.jobAssignmentData?.candidateName || ""}
              fullWidth
              inputProps={{ readOnly: expense?.id && !expense.recruiterId }}
            />
          </Grid>
          <Grid item xs={6}>
            {expense.jobAssignmentData && (
              <TextField
                id="jobAssignment"
                name="jobAssignment"
                label="Job Assignment"
                type="jobAssignment"
                variant="outlined"
                value={`\nID: ${expense.jobAssignmentData.laborEdgeJobId} \nASSIGNMENT PERIOD: ${expense.jobAssignmentData.period} \n${expense.jobAssignmentData.clientName} - ${expense.jobAssignmentData.clientLocation}`}
                fullWidth
                disabled
                multiline
                rows={5}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <NumberFormat
              id="payamount-input"
              label="Amount"
              name="amount"
              fullWidth
              variant="outlined"
              value={expense.amount}
              onValueChange={handleAmountChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: !payDateAndAmountEditable || isReadOnly,
              }}
              customInput={TextField}
              thousandSeparator
              isNumericString
              fixedDecimalScale={true}
              decimalScale={2}
              prefix="$"
              isAllowed={isAmountAllowed}
              placeholder="$0.00"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="paydate-input"
              label="Pay Date"
              type="text"
              name="payDate"
              fullWidth
              variant="outlined"
              value={expense.payDate ? moment(expense.payDate).format(DATE_FORMAT_US) : ""}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
                endAdornment: <CalendarToday />,
              }}
              onClick={!isReadOnly ? openDatePicker : undefined}
            />
            {
              <Popper placement="bottom" open={datePickerOpen} anchorEl={datePickerAnchor}>
                <Paper>
                  <DayPicker
                    selectedDays={expense.payDate ? new Date(expense.payDate) : undefined}
                    onDayClick={payDateSelected}
                    disabledDays={payDateDisabledDays}
                    fromMonth={minDate}
                    toMonth={maxDate}
                    initialMonth={minDate}
                    month={
                      expense.payDate
                        ? moment(minDate).isAfter(expense.payDate)
                          ? minDate
                          : new Date(expense.payDate)
                        : minDate
                    }
                  />
                </Paper>
              </Popper>
            }
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="expenseType">Expense Type</InputLabel>
              <Select
                key={expense.typeId}
                defaultValue={expense.typeId || ""}
                labelId="expenseType-label"
                id="expenseType"
                label="expenseType"
                fullWidth
                type="expenseType"
                name="expenseType"
                disabled={!payDateAndAmountEditable}
                inputProps={{
                  readOnly: isReadOnly,
                }}
                onChange={onExpenseTypeChange}>
                {expenseTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {!isReadOnly && (
          <CanRenderChildren permissionName="canUploadExpenseFile">
            <FileDragAndDrop
              onFileUploaded={uploadExpenseFile}
              expenseId={expense.id}
              disabledReason={getDisabledReason(expense, uploadFileIds)}
              fileIds={uploadFileIds}
              acceptedFiles={acceptedFiles}
            />
          </CanRenderChildren>
        )}

        {uploadFileIds.length > 0 &&
          uploadFileIds.map((uploadFileId) => (
            <ExpenseFile
              expenseFileId={uploadFileId}
              onFileUploaded={uploadExpenseFile}
              onDelete={() => deleteExpenseFile(uploadFileId)}
              onFileUpdate={updateExpenseFile}
              uploadFileId={uploadFileId}
              isReadOnly={isReadOnly}
              key={uploadFileId}
            />
          ))}

        <NotesSection
          notes={expense.notes}
          note={newNote}
          onNoteChange={onNoteChange}
          noteTypeFilter={["ExpenseNote"]}
          isReadOnly={blockNoteSection}
        />
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} mt={3}>
          <Box>
            <Button
              onClick={() => history.push("/expenses")}
              variant="contained"
              size="small"
              className={"timecard-detail-bottombar-button"}>
              <ArrowBack className={"timecard-detail-bottombar-icon"} />
              Back
            </Button>
            {expenseCanBeRejected && (
              <LoadingButton
                variant="contained"
                size="small"
                disabled={rejectDisabled}
                onClick={handleReject}
                loading={rejectLoading}
                className={"timecard-detail-bottombar-button"}>
                <AssignmentReturn className={"timecard-detail-bottombar-icon"} />
                Reject
              </LoadingButton>
            )}
          </Box>
          <Box>
            {expenseCanBeDeleted && (
              <LoadingButton
                variant="contained"
                size="small"
                disabled={!expenseCanBeDeleted}
                onClick={handleDelete}
                loading={deleteLoading}
                className={"timecard-detail-bottombar-button"}>
                <Delete className={"timecard-detail-bottombar-icon"} />
                Delete
              </LoadingButton>
            )}
            {expenseCanBeApproved && (
              <LoadingButton
                variant="contained"
                size="small"
                disabled={approveDisabled}
                onClick={handleApprove}
                className={"timecard-detail-bottombar-button"}
                loading={approveLoading}>
                <ApproveIcon className={"timecard-detail-bottombar-icon"} />
                Approve
              </LoadingButton>
            )}
            {expenseCanBeSaved && (
              <LoadingButton
                variant="contained"
                size="small"
                disabled={saveDisabled}
                onClick={handleSave}
                className={"timecard-detail-bottombar-button"}
                loading={saveLoading}>
                <Save className={"timecard-detail-bottombar-icon"} />
                Save
              </LoadingButton>
            )}
            {expenseCanBeSubmitted && (
              <LoadingButton
                variant="contained"
                size="small"
                disabled={submitDisabled}
                onClick={handleSubmit}
                loading={submitLoading}
                className={"timecard-detail-bottombar-button"}>
                <Send className={"timecard-detail-bottombar-icon"} />
                Submit
              </LoadingButton>
            )}
          </Box>
        </Box>
      </form>
      {expenseCanBeDeleted && (
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          onClose={closeConfirmDialog}
          onConfirm={onConfirmExpenseDelete}
          content="Are you sure you want to delete this expense?"
          dialogTitle="Delete Expense"
        />
      )}
      <ExpenseJobAssignmentSelector
        recruiterId={expense.recruiterId}
        open={openTravelerDialog}
        onClose={() => setOpenTravelerDialog(false)}
        onSelect={handleSelectedAssignment}
      />
      <AlertDialog
        isOpen={isExpensePayDateDialogOpen}
        onClose={closeExpensePayDateDialog}
        dialogText="The pay date selected is after the job assignment"
        dialogTitle="Warning"
      />
    </div>
  );
};

export default ExpenseDetails;
