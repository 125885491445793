import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableBody, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import { useSnackbar } from "notistack";
import PayrollLocksApi from "../../api/PayrollLocksApi";
import SwitchPayPeriodApi from "../../api/SwitchPayPeriodApi";
import ConfirmDialog from "../shared/components/ConfirmDialog";
import InformationDialog from "../shared/components/InformationDialog";
import { DATES_CHANGED, TITLE_CHANGED } from "../../redux/actions/actionTypes";
import RefreshIcon from "@mui/icons-material/Refresh";
import AdfApi from "../../api/AdfApi";
import HolidaysRow from "./holidaysRow";
import CanRenderChildren from "components/shared/functions/CanRenderChildren";
import { useAppDispatch } from "hooks";
import { LockPeriodStatus } from "types/payPeriod/lockPayPeriodStatus";
import { LastAdfRun } from "types/payPeriod/lastRunAdf";
import { LockType } from "types/payPeriod/lockType";
import { parseErrorResponse } from "commons/utils";

const ERROR_UNLOCKING = "An error occurred while unlocking the payroll.";

const UnlockPayPeriod = () => {
  const [lockPeriods, setlockPeriods] = useState<LockPeriodStatus[] | null>(null);
  const [lastAdfRun, setLastAdfRun] = useState<LastAdfRun | null>(null);
  const [isQuestionDialogOpen, SetQuestionDialogOpen] = React.useState(false);
  const [isInformationDialogOpen, SetInformationDialogOpen] = React.useState(false);
  const [validationsResults, setValdiationResults] = React.useState([]);
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const getPeriodStatus = async () => {
    try {
      const response = await PayrollLocksApi.GetPayrollLockStatus();
      setlockPeriods(response);
    } catch (error: any) {
      if (error.status === 404) {
        error.json().then((e: any) => {
          snackbar.enqueueSnackbar(`Error getting the lock period status: ${e.reasonPhrase}`);
        });
      } else {
        snackbar.enqueueSnackbar(`Error getting the lock period status: ${error}`);
      }
    }
  };

  const getLastAdfRun = () => {
    AdfApi.GetLastAdfRun()
      .then((response) => {
        setLastAdfRun(response);
      })
      .catch((e) => {
        if (e.status === 404) {
          e.json().then((e: any) => {
            snackbar.enqueueSnackbar(`Error getting last ADF run: ${e.reasonPhrase}`);
          });
        } else {
          snackbar.enqueueSnackbar(`Error getting last ADF run: ${e}`);
        }
      });
  };

  useEffect(() => {
    dispatch({ type: TITLE_CHANGED, title: "Pay Period Settings" });
    getPeriodStatus();
    GetActivePayPeriod();
    getLastAdfRun();
  }, []);

  const GetActivePayPeriod = () => {
    SwitchPayPeriodApi.GetActivePayPeriod()
      .then((response) => {
        console.log(response);
      })
      .catch((e) => {
        snackbar.enqueueSnackbar(`Error gettting the active pay period: ${e}`);
      });
  };

  const switchPayPeriod = () => {
    SwitchPayPeriodApi.SwitchPayPeriodPayPeriod()
      .then((newPeriod) => {
        dispatch({
          type: DATES_CHANGED,
          startDate: newPeriod.periodStartDate,
          endDate: newPeriod.periodEndDate,
        });
        snackbar.enqueueSnackbar(`The pay period was changed.`);
      })
      .catch((e) => {
        e.json().then((errors: any) => {
          setValdiationResults(errors);
          SetInformationDialogOpen(true);
        });
      });
  };

  const runAdf = () => {
    AdfApi.StartAdfRun().then((response) => {
      snackbar.enqueueSnackbar(`ADF run queued with run ID ${response}`);
      getLastAdfRun();
    });
  };

  function addSpaces(str: string) {
    return str.replace(/([A-Z])/g, " $1");
  }

  const handleClick = async (lockType: LockType) => {
    try {
      await PayrollLocksApi.UpdatePayrollLockStatus(lockType, "unlocked");
      getPeriodStatus();
      snackbar.enqueueSnackbar("Payroll unlocked.");
    } catch (error: any) {
      const errorMessage = await parseErrorResponse(error, ERROR_UNLOCKING);
      snackbar.enqueueSnackbar(errorMessage);
    }
  };

  const openQuestionDialog = () => {
    SetQuestionDialogOpen(true);
  };

  const onCloseSwitchPayPeriodDialog = () => {
    SetQuestionDialogOpen(false);
  };

  const onCloseInformationDialog = () => {
    SetInformationDialogOpen(false);
  };

  const onYesSwitchPayPeriodDialog = () => {
    switchPayPeriod();
    SetQuestionDialogOpen(false);
  };

  const handleHolidaysnackBarMsg = (msg: string) => {
    snackbar.enqueueSnackbar(msg);
  };

  return (
    <div className="view-container">
      {lockPeriods !== null && (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <div style={{ display: "flex" }}>
                  <h2>Run ADF</h2>
                  <Tooltip title="Refresh" placement="right">
                    <Button onClick={() => getLastAdfRun()}>
                      <RefreshIcon color="primary" />
                    </Button>
                  </Tooltip>
                </div>
              </TableCell>
              {lastAdfRun !== null ? (
                <TableCell>
                  <h3 className="timecards-home-title">
                    Status:{" "}
                    <span className="timecards-home-title-date">
                      <strong>{addSpaces(lastAdfRun.status)}</strong>
                    </span>
                  </h3>
                  <h3 className="timecards-home-title">
                    Run ID:{" "}
                    <span className="timecards-home-title-date">
                      <strong>{addSpaces(lastAdfRun.runId)}</strong>
                    </span>
                  </h3>

                  <h3 className="timecards-home-title">
                    Run Start:{" "}
                    <span className="timecards-home-title-date">
                      <strong>{moment(lastAdfRun.runStart).format("MM/DD/YYYY hh:mm:ss A")}</strong>
                    </span>
                  </h3>
                  {lastAdfRun.status == "Succeeded" && (
                    <h3 className="timecards-home-title">
                      Run End:{" "}
                      <span className="timecards-home-title-date">
                        <strong>{moment(lastAdfRun.runEnd).format("MM/DD/YYYY hh:mm:ss A")}</strong>
                      </span>
                    </h3>
                  )}
                  <Typography className="timecards-pay-period"></Typography>
                </TableCell>
              ) : (
                <TableCell>
                  {" "}
                  <h3 className="timecards-home-title">
                    No ADF run information for the last 24 hours
                  </h3>
                </TableCell>
              )}
              <TableCell>
                <Button
                  variant="contained"
                  disabled={lastAdfRun !== null && lastAdfRun?.status !== "Succeeded"}
                  onClick={() => runAdf()}>
                  Trigger ADF Run
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h2>Main Payroll</h2>
              </TableCell>
              <TableCell>
                <h3 className="timecards-home-title">
                  Status:{" "}
                  <span className="timecards-home-title-date">
                    <strong>{lockPeriods[0].Status}</strong>
                  </span>
                </h3>
                {lockPeriods[0].IsLocked && (
                  <h3 className="timecards-home-title">
                    Lock Date:{" "}
                    <span className="timecards-home-title-date">
                      <strong>
                        {moment(lockPeriods[0].LockDate).format("MM/DD/YYYY hh:mm:ss A")}
                      </strong>
                    </span>
                  </h3>
                )}
                <Typography className="timecards-pay-period"></Typography>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  disabled={!(lockPeriods[0].IsLocked && !lockPeriods[1].IsLocked)}
                  onClick={async () => await handleClick(lockPeriods[0].LockType)}>
                  Unlock Pay Period
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h2>Late Payroll</h2>
              </TableCell>
              <TableCell>
                <h3 className="timecards-home-title">
                  Status:{" "}
                  <span className="timecards-home-title-date">
                    <strong>{lockPeriods[1].Status}</strong>
                  </span>
                </h3>
                {lockPeriods[1].IsLocked && (
                  <h3 className="timecards-home-title">
                    Lock Date:{" "}
                    <span className="timecards-home-title-date">
                      <strong>
                        {moment(lockPeriods[1].LockDate).format("MM/DD/YYYY hh:mm:ss A")}
                      </strong>
                    </span>
                  </h3>
                )}
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  disabled={!lockPeriods[1].IsLocked}
                  onClick={async () => await handleClick(lockPeriods[1].LockType)}>
                  Unlock Pay Period
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <h2>Switch Pay Period</h2>
              </TableCell>
              <TableCell />
              <TableCell>
                <Button variant="contained" onClick={() => openQuestionDialog()}>
                  Next Pay Period
                </Button>
              </TableCell>
            </TableRow>
            <CanRenderChildren permissionName="canCheckHolidays">
              <HolidaysRow callbackSnackBar={handleHolidaysnackBarMsg} />
            </CanRenderChildren>
          </TableBody>
        </Table>
      )}
      <ConfirmDialog
        isOpen={isQuestionDialogOpen}
        onClose={onCloseSwitchPayPeriodDialog}
        onConfirm={onYesSwitchPayPeriodDialog}
        content="Are you sure you want to switch to the next pay period?"
        dialogTitle="Switch Pay Period"
        confirmText="Yes"
        cancelText="No"
      />
      <InformationDialog
        isOpen={isInformationDialogOpen}
        onClose={onCloseInformationDialog}
        dialogTextList={validationsResults}
        dialogTitle="Switch Pay Period validation errors"
      />
    </div>
  );
};

export default UnlockPayPeriod;
