import React from "react";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import FacilityReportAttachedIcon from "../shared/components/FacilityReportAttachedIcon";
import FileFordwardedIcon from "../shared/components/FileFordwardedIcon";
import Paper from "@mui/material/Paper";
import TimeCardAdjusmentsIcon from "../shared/components/TimeCardAdjusmentsIcon";
import CanRenderChildren from "../shared/functions/CanRenderChildren";
import TimecardIsLateIcon from "../shared/components/TimecardIsLateIcon";
import TimecardFileIcon from "../shared/components/TimecardFileIcon";
import SignatureIcon from "components/shared/components/SignatureIcon";
import HightlightIcon from "components/shared/components/HighlightIcon";
import { useHighlightIcon } from "hooks";

type TimecardTileProps = {
  onClick: () => void;
  selected: boolean;
  forwardingHistory?: string;
  fetchAssignments: () => void;
  timesheet: any;
};

export default function TimecardTile(props: TimecardTileProps) {
  const { iconColor, showTooltip, tooltipText } = useHighlightIcon(props.timesheet);
  const isDuplicate = !!props.timesheet.isDuplicate;
  const hasTimecard = !!props.timesheet.timecardUploadId;
  const hasFacilityReport = !!props.timesheet.facilityReportUploadId;

  return (
    <Paper onClick={props.onClick}>
      <div
        className={
          "timecard-tile timecard-tile-no-overflow " +
          (props.selected ? "selected" : "") +
          (showTooltip ? " highlight" : "") +
          (isDuplicate ? " duplicate" : "")
        }>
        <ListItemText
          primary={
            <p className="timecard-tile-fullname">
              {props.timesheet.candidateFullName} |{" "}
              <span style={{ fontSize: "smaller" }}>Job Id: {props.timesheet.laborEdgeJobId}</span>
            </p>
          }
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className="timecard-tile-location"
                color="textPrimary">
                {props.timesheet.clientName} - {props.timesheet.clientLocation}
              </Typography>
            </React.Fragment>
          }
        />
        <div className="timecard-tile-fileicons">
          <TimecardIsLateIcon isLate={props.timesheet.isLate} />

          <SignatureIcon
            signatureStatus={props.timesheet.managerSignatureStatus}
            hassEditRequest={props.timesheet.hasEditRequest}
            timesheetId={props.timesheet.id}
            facilityId={props.timesheet.clientId}
            refreshData={props.fetchAssignments}
          />

          <FacilityReportAttachedIcon timesheetId={props.timesheet.id} filled={hasFacilityReport} />

          <TimecardFileIcon
            timecardUploadId={hasTimecard ? props.timesheet.id : null}
            uploadSource={hasTimecard ? props.timesheet.timecardUploadSource : null}
          />

          <HightlightIcon
            showTooltip={showTooltip}
            iconColor={iconColor}
            tooltipText={tooltipText}></HightlightIcon>

          <FileFordwardedIcon
            forwardingStatus={props.timesheet.forwardingStatus}
            timesheetId={props.timesheet.id}
            icon="forward_to_inbox"
          />

          <CanRenderChildren featureFlagName="isAdjustmentsOn">
            <TimeCardAdjusmentsIcon
              isAdjustment={props.timesheet.isAdjustment}
              hasAdjustment={props.timesheet.hasAdjustment}
              dtHoursAdjusted={props.timesheet.dtHoursAdjusted}
            />
          </CanRenderChildren>
        </div>
      </div>
    </Paper>
  );
}
