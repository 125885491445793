import { TableRow, TableCell, Button, Tooltip, Box } from "@mui/material";
import { fetchHolidays, saveHolidays } from "api/HolidaysApi";
import moment from "moment";
import React, { useState, useEffect } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { useSelector } from "react-redux";
import { Holiday, HolidayType } from "types/holidays";
import NextYearHolidayDialog from "./NextYearHolidayDialog";
import { LoadingButton } from "shared/loadingButton/LoadingButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type HolidaysRowProps = {
  callbackSnackBar: (msg: string) => void;
};

const HolidaysRow = ({ callbackSnackBar }: HolidaysRowProps) => {
  const startDate = useSelector((state: RootState) => state.header.startDate);
  const endDate = useSelector((state: RootState) => moment(state.header.endDate));
  const [isNextYearHolidayDialogOpen, setNextYearHolidayDialogOpen] = useState(false);
  const [isLoadingNextYearHoliday, setIsLoadingNextYearHoliday] = useState(false);
  const [nextYearHolidaySelected, setNextYearHolidaySelected] = useState<Holiday | null>(null);
  const [holidays, setHolidays] = useState<Holiday[]>([]);

  const getHolidays = async () => {
    try {
      const response = await fetchHolidays();
      setHolidays(response);
    } catch (e) {
      callbackSnackBar("Error on getting Holidays");
    }
  };

  useEffect(() => {
    getHolidays();
  }, []);

  const handleOnSave = async (holidayId: string) => {
    try {
      const holidayToUpdate = holidays.find((holiday) => {
        return holiday.id === holidayId;
      });

      if (!holidayToUpdate) return;

      await saveHolidays(holidayId, holidayToUpdate);
      await getHolidays();
      callbackSnackBar("Changes saved!");
    } catch (e) {
      callbackSnackBar("Error on save Holidays");
    }
  };

  const handleDayChange = (date: Date, id: string) => {
    if (!holidays) return;

    const normalizedDate = new Date(moment(date).format("MM/DD/YYYY"));

    const updatedHoliday = holidays.find((holiday) => {
      return holiday.id === id;
    });

    if (!updatedHoliday) return;
    updatedHoliday.date = normalizedDate;
  };

  function getNextYearDate(date: string) {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() + 1);
    return dateCopy;
  }

  const openNextYearHolidayDialogOpen = (
    e: boolean,
    holiday: Holiday,
    nextYearHoliday?: Holiday
  ) => {
    if (nextYearHoliday) {
      setNextYearHolidaySelected(nextYearHoliday);
    } else {
      setNextYearHolidaySelected({
        id: "00000000-0000-0000-0000-000000000000",
        date: getNextYearDate(moment(holiday.date).format("MM/DD/YYYY")),
        type: holiday.type,
        name: holiday.name,
        description: holiday.description,
      });
    }
    setNextYearHolidayDialogOpen(e);
  };

  const handleNextYearHolidayOnConfirm = async () => {
    await getHolidays();
    setNextYearHolidayDialogOpen(false);
  };

  const handleOnCancelNextYearHoliday = () => {
    setNextYearHolidayDialogOpen(false);
  };

  const handleNextYearHolidayLoader = (e: boolean) => {
    setIsLoadingNextYearHoliday(e);
  };

  return (
    <TableRow>
      <TableCell>
        <h2>Holidays</h2>
      </TableCell>
      <TableCell>
        <h4>Static holidays</h4>
        {holidays
          .filter(
            (holiday) =>
              holiday.type === HolidayType.Static &&
              new Date(holiday.date).getFullYear() === new Date().getFullYear()
          )
          .sort((a, b) => {
            const dateA = moment(a.date);
            const dateB = moment(b.date);

            const monthDiff = dateA.month() - dateB.month();
            const dayDiff = dateA.date() - dateB.date();

            if (monthDiff !== 0) {
              return monthDiff;
            }

            return dayDiff;
          })
          .map((holiday, index) => (
            <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
              <label>{holiday.description}</label>
              <label>{moment(holiday.date).format("MMM Do YY")}</label>
            </div>
          ))}

        <h4>Dynamic holidays</h4>
        {holidays
          .filter((holiday) => holiday.type === HolidayType.Dynamic)
          .map((holiday, index) => {
            if (new Date(holiday.date).getFullYear() === new Date().getFullYear()) {
              const nextYearHoliday = holidays.find(
                (h) =>
                  h.name === holiday.name &&
                  h.type === HolidayType.Dynamic &&
                  new Date(h.date).getFullYear() === new Date().getFullYear() + 1
              );
              const diableDateFrom = new Date(new Date().getFullYear(), 11, 31);
              const disableSaveButton =
                holiday.date &&
                (moment(holiday.date).startOf("day").isBetween(startDate, endDate) ||
                  moment(new Date())
                    .startOf("day")
                    .isSameOrAfter(moment(holiday.date).startOf("day")));

              return (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  key={index}>
                  <div style={{ minWidth: 130 }}>
                    <label>{holiday.description}</label>
                  </div>
                  <Box p={1}>
                    <DayPickerInput
                      value={holiday.date ? moment(holiday.date).format("MM/DD/YYYY") : undefined}
                      onDayChange={(date: Date) => handleDayChange(date, holiday.id)}
                      dayPickerProps={{
                        disabledDays: { after: diableDateFrom, before: new Date() },
                      }}
                    />
                    {nextYearHoliday && (
                      <Tooltip
                        title={
                          "Next year holiday: " + moment(nextYearHoliday.date).format("MM/DD/YYYY")
                        }>
                        <InfoOutlinedIcon
                          htmlColor="#50478b"
                          style={{
                            display: "inline-flex",
                            marginRight: "5px",
                            verticalAlign: "middle",
                            marginLeft: "5px",
                            width: "16px",
                            height: "16px",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                  <Box py={1} gap={1} display={"flex"}>
                    <Button
                      variant="contained"
                      onClick={() => handleOnSave(holiday.id)}
                      disabled={disableSaveButton}>
                      Save
                    </Button>
                    <LoadingButton
                      variant="contained"
                      onClick={() => openNextYearHolidayDialogOpen(true, holiday, nextYearHoliday)}
                      loading={isLoadingNextYearHoliday}>
                      Next Year
                    </LoadingButton>
                  </Box>
                </Box>
              );
            }
          })}
        {isNextYearHolidayDialogOpen && nextYearHolidaySelected && (
          <NextYearHolidayDialog
            open={isNextYearHolidayDialogOpen}
            onClose={handleOnCancelNextYearHoliday}
            toggleLoader={handleNextYearHolidayLoader}
            holiday={nextYearHolidaySelected}
            onConfirm={handleNextYearHolidayOnConfirm}
          />
        )}
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

export default HolidaysRow;
