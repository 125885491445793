import { getNetSuiteDataExport, getUkgDataExport } from "api/ReferralsExportApi";
import LoggedUser from "../../../commons/LoggedUser";
import { downloadBlob } from "../../../commons/utils";
import { ReferralTableItem, ReferralsUKGResponse } from "types/referrals";
import { getDataExport } from "api/DataExportApi";

export async function downloadUltiProDataExport(lateTimecards: boolean): Promise<void> {
  if (LoggedUser.isRecruiter() || LoggedUser.isSalesRep() || LoggedUser.isAssistant()) return;

  const query = {
    name: "UltiPro",
    parameters: [
      {
        name: "LateTimecards",
        value: lateTimecards.toString(),
      },
    ],
  };

  try {
    const blob = await getDataExport(query, "text/csv");
    await downloadBlob(new Blob([blob]), "report.csv");
  } catch (error) {
    alert(error);
  }
}

export async function downloadReferralsDataExport(
  referrals: ReferralTableItem[],
  isFinalDownload: boolean,
  ukg: boolean
): Promise<ReferralsUKGResponse> {
  if (ukg) {
    return await getUkgDataExport(referrals, isFinalDownload, "text/csv");
  } else {
    return await getNetSuiteDataExport(referrals, isFinalDownload, "text/csv");
  }
}
