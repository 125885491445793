import React from "react";
import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@mui/material";

type TimeCardAdjusmentsIconProps = {
  isAdjustment: boolean;
  hasAdjustment: boolean;
  dtHoursAdjusted: boolean;
};

export default function TimeCardAdjusmentsIcon({
  hasAdjustment,
  isAdjustment,
  dtHoursAdjusted,
}: TimeCardAdjusmentsIconProps) {
  const icon = "build";
  let toolTipText = "";
  if (isAdjustment) {
    toolTipText = "This is an adjustment timecard.";
  } else if (hasAdjustment) {
    toolTipText = "This timecard contains adjusted items.";
  } else if (dtHoursAdjusted) {
    toolTipText = "This timecard contains edited items.";
  }

  const renderAvatar = () => {
    if (isAdjustment) {
      return (
        <Avatar
          sx={{
            backgroundColor: "rgb(255, 145, 0)!important",
          }}>
          <i className="material-icons-outlined">{icon}</i>
        </Avatar>
      );
    } else if (hasAdjustment) {
      return (
        <Avatar
          sx={{
            backgroundColor: "rgb(134, 195, 78)!important",
          }}>
          <i className="material-icons-outlined">{icon}</i>
        </Avatar>
      );
    } else if (dtHoursAdjusted) {
      return (
        <Avatar
          sx={{
            backgroundColor: "rgb(10, 160, 220)!important",
          }}>
          <i className="material-icons-outlined">{icon}</i>
        </Avatar>
      );
    } else {
      return (
        <Avatar className={`file-attached-indicator`}>
          <i className="material-icons-outlined">{icon}</i>
        </Avatar>
      );
    }
  };

  return (
    <Tooltip title={toolTipText} placement="top">
      {renderAvatar()}
    </Tooltip>
  );
}
