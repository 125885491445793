import { Moment } from "moment";
import { Manager } from "types/facility/managers";
import { msalApiFetch } from "../commons/authConfig";
import { NoteType } from "../commons/Enums";
import { HistoryExpense } from "types/expenses/Expense";

const baseUrl = process.env.REACT_APP_API_URL;
const endpoint = "timesheet";

export default {
  //Api to fetch timecard detail
  async fetchTimecardDetail(assignmentId: string) {
    const url = `${baseUrl}/${endpoint}/${assignmentId}`;

    const results = await msalApiFetch(url);
    return results.json();
  },
  /**
   * Api to upload timecard or facility report files
   * @param {"timecard"|"facilityReport"} type
   * @param {string} selectedAssignmentId
   * @param {*} files
   */
  fileUpload(type: string, selectedAssignmentId: null, files: any[]) {
    const fileName = files[0].name.split(".").pop();
    const url = `${baseUrl}/${endpoint}/${type}/${selectedAssignmentId}.${fileName}`;

    return msalApiFetch(url, {
      method: "POST",
      body: files[0],
    });
  },
  /**
   * Api to add files to existing timecard or facility report file
   * @param {"timecard"|"facilityReport"} type
   * @param {string} selectedTimesheetId
   * @param {File[]} files
   * @returns
   */
  addFiles(type: string, selectedTimesheetId: null, files: any[]) {
    const url = `${baseUrl}/${endpoint}/${selectedTimesheetId}/${type}/addFiles`;
    const formData = new FormData();
    files.forEach((file: string | Blob) => {
      formData.append("files", file);
    });

    return msalApiFetch(url, {
      method: "POST",
      body: formData,
    });
  },
  late(assignmentId: any) {
    const url = `${baseUrl}/${endpoint}/${assignmentId}/late`;

    return msalApiFetch(url, { method: "post" });
  },
  undoLate(assignmentId: any) {
    const url = `${baseUrl}/${endpoint}/${assignmentId}/undo-late`;

    return msalApiFetch(url, { method: "post" });
  },
  /**
   * Set timesheet as Not Worked or Not Submited and No Expenses
   * @param {string} timesheetId
   * @param {number} subStatus
   */
  notWorkedAndNoExpenses(timesheetId: any, subStatus: number) {
    const url = `${baseUrl}/${endpoint}/${timesheetId}/nwne/${subStatus}`;

    return msalApiFetch(url, { method: "post" });
  },

  async convertToExpenseTimecard(timesheetId: string) {
    const url = `${baseUrl}/timesheets/${timesheetId}/convert-expense-timesheet`;

    const response = await msalApiFetch(url, { method: "post" });

    if (!response.ok) throw response;
  },

  //Api to save form.
  async save(
    assignment: {
      id: any;
      timecardEntries: any;
      billingEntries: any;
      billId: any;
      taxableExpenses: any;
      nonTaxableExpenses: any;
      bonusExpenses: any;
      timecardAdjustmentTypes: any;
      nonTaxableAdjustmentTypes: any;
      note: any;
      noteType: any;
      noteTypesToCreate: any;
      notifyTraveler: any;
      callOffEntries: any[];
    },
    submitStatus: string
  ) {
    const url = `${baseUrl}/${endpoint}/${assignment.id}/save`;
    const results = await msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Timesheet: {
          TimesheetId: assignment.id,
          TimecardTypes: assignment.timecardEntries,
          BillingTypes: assignment.billingEntries,
          BillId: assignment.billId,
          TaxableTypes: assignment.taxableExpenses,
          NonTaxableTypes: assignment.nonTaxableExpenses,
          BonusTypes: assignment.bonusExpenses,
          Status: submitStatus,
          timecardAdjustmentTypes: assignment.timecardAdjustmentTypes,
          NonTaxableAdjustmentTypes: assignment.nonTaxableAdjustmentTypes,
          CallOffsTypes: assignment.callOffEntries,
        },
        Note: assignment.note
          ? {
              TimeSheetId: assignment.id,
              Contents: assignment.note,
              NoteType: assignment.noteType,
              NoteTypesToCreate: assignment.noteTypesToCreate,
              NotifyToTraveler: assignment.notifyTraveler,
            }
          : null,
      }),
    });
    if (!results.ok) throw results;
    return results.json();
  },
  //Api to change status to Pending.
  returnToPending(assignment: { id: any; note: any; notifyTraveler: any }) {
    const url = `${baseUrl}/${endpoint}/${assignment.id}/return`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        TimeSheetId: assignment.id,
        Contents: assignment.note,
        NoteType: NoteType.Recruiter,
        NotifyToTraveler: assignment.notifyTraveler,
      }),
    });
  },

  reject(assignment: { id: any; note: any; notifyTraveler?: any }) {
    const url = `${baseUrl}/${endpoint}/${assignment.id}/reject`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        TimeSheetId: assignment.id,
        Contents: assignment.note,
        NoteType: NoteType.Recruiter,
        NotifyToTraveler: assignment.notifyTraveler,
      }),
    });
  },

  acceptEditRequest(assignment: { id: any; note: any; notifyTraveler: any }) {
    const url = `${baseUrl}/timesheets/${assignment.id}/accept-edit-request`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        TimeSheetId: assignment.id,
        Contents: assignment.note,
        NoteType: NoteType.Recruiter,
        NotifyToTraveler: assignment.notifyTraveler,
      }),
    });
  },

  declineEditRequest(assignment: { id: any; note: any; notifyTraveler: any }) {
    const url = `${baseUrl}/timesheets/${assignment.id}/decline-edit-request`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        TimeSheetId: assignment.id,
        Contents: assignment.note,
        NoteType: NoteType.Recruiter,
        NotifyToTraveler: assignment.notifyTraveler,
      }),
    });
  },

  approve(assignment: {
    id: any;
    billId: any;
    billingEntries: any;
    note: any;
    notifyTraveler: any;
  }) {
    const url = `${baseUrl}/${endpoint}/${assignment.id}/approve`;

    const messageBody = JSON.stringify({
      TimeSheetId: assignment.id,
      Timesheet: {
        BillId: assignment.billId,
        BillingTypes: assignment.billingEntries,
      },
      Note: assignment.note
        ? {
            TimeSheetId: assignment.id,
            Contents: assignment.note,
            NoteType: NoteType.Recruiter,
            NotifyToTraveler: assignment.notifyTraveler,
          }
        : null,
    });

    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: messageBody,
    });
  },
  // Api to Billing form.
  billing(assignmentId: any, messageBody: string) {
    const url = `${baseUrl}/${endpoint}/${assignmentId}/billing`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: messageBody,
    });
  },

  /**
   * Saves a note in a timsheet
   * @param {*} assignment a timesheet
   * @param {boolean} isSystemGenerated If the note is automatically generated
   * @returns
   */
  noteSave(assignment: { id: any; note: any }, isSystemGenerated = false) {
    const url = `${baseUrl}/${endpoint}/${assignment.id}/notes/save`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify([
        {
          TimeSheetId: assignment.id,
          Contents: assignment.note,
          NoteType: NoteType.Payroll,
          IsSystemGenerated: isSystemGenerated,
        },
      ]),
    });
  },
  async activePeriod(period: number) {
    const url = `${baseUrl}/${endpoint}/activeperiod/${period}`;
    const results = await msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (!results.ok) throw results;
    return results.json();
  },
  async createNewTimecard(selectedAssignmentId: string | null, startDate: Moment, endDate: Moment) {
    const url = `${baseUrl}/${endpoint}/custom`;
    const result = await msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        DealSheetId: selectedAssignmentId,
        StartDate: startDate.format("YYYY/MM/DD"),
        EndDate: endDate.format("YYYY/MM/DD"),
      }),
    });
    if (result.ok) {
      return result.json();
    } else {
      throw result;
    }
  },

  async createNewAdjustmentTimecard(selectedAssignmentId: string | null) {
    const url = `${baseUrl}/${endpoint}/adjustment`;
    const result = await msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        DealSheetId: selectedAssignmentId,
      }),
    });
    if (result.ok) {
      return result.json();
    } else {
      throw result;
    }
  },

  async valdiateNewAdjustmentTimecard(selectedAssignmentId: string | null) {
    const url = `${baseUrl}/${endpoint}/adjustment/validate`;
    const result = await msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        DealSheetId: selectedAssignmentId,
      }),
    });
    if (result.ok) {
      return result.json();
    } else {
      throw result;
    }
  },

  async getNotesByAssigment(assignmentId: any) {
    const url = `${baseUrl}/${endpoint}/${assignmentId}/Notes`;
    const results = await msalApiFetch(url);
    if (!results.ok) throw results;
    return results.json();
  },
  deleteTimecard(assignmentId: any) {
    const url = `${baseUrl}/${endpoint}/${assignmentId}`;
    return msalApiFetch(url, { method: "delete" });
  },

  updateTimecard(
    assignment: {
      id: any;
      timecardEntries: any;
      billingEntries: any;
      billId: any;
      taxableExpenses: any;
      nonTaxableExpenses: any;
      bonusExpenses: any;
      note: any;
      notifyTraveler: any;
    },
    submitStatus: any
  ) {
    const url = `${baseUrl}/${endpoint}/${assignment.id}`;
    return msalApiFetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Timesheet: {
          TimesheetId: assignment.id,
          TimecardTypes: assignment.timecardEntries,
          BillingTypes: assignment.billingEntries,
          BillId: assignment.billId,
          TaxableTypes: assignment.taxableExpenses,
          NonTaxableTypes: assignment.nonTaxableExpenses,
          BonusTypes: assignment.bonusExpenses,
          Status: submitStatus,
        },
        Note: assignment.note
          ? {
              TimeSheetId: assignment.id,
              Contents: assignment.note,
              NoteType: NoteType.Recruiter,
              NotifyToTraveler: assignment.notifyTraveler,
            }
          : null,
      }),
    });
  },
  async downloadTimesheetsDuplicatedSSNReport() {
    const url = `${baseUrl}/timesheet/DuplicatedReport`;
    const response = await msalApiFetch(url, {
      method: "GET",
    });
    return response.blob();
  },

  async downloadTimesheetsWithoutManagerSignatureReport() {
    const url = `${baseUrl}/timecards/TimecardsMissingManagerSignatureReport`;
    const response = await msalApiFetch(url, {
      method: "GET",
    });
    return response.blob();
  },

  async downloadTimesheetsPendingEditRequest() {
    const url = `${baseUrl}/timecards/TimecardsInEditRequestPending`;
    const response = await msalApiFetch(url, {
      method: "GET",
    });
    return response.blob();
  },
  /**
   * Reset the current state of a Did Not Work timesheet to Pending
   * @param {String} timesheetId
   */
  async resetDidNotWork(timesheetId: string, note: string) {
    const noteContent = note.trim().length > 0 ? note : null;
    const result = await msalApiFetch(`${baseUrl}/${endpoint}/${timesheetId}/reset-did-not-work`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ note: noteContent }),
    });
    if (!result.ok) throw result;
  },
  /**
   * Get the recruiter NetSuite lock status
   * @param {String} timesheetId
   */
  async getRecruiterNetSuiteLockStatus(timesheetId: string) {
    const result = await msalApiFetch(
      `${baseUrl}/${endpoint}/${timesheetId}/recruiter-lock-status`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );
    if (!result.ok) throw result;
    return result.json();
  },
  /**
   * Acknowledge the notes for the user
   * @param {string} timesheetId
   */
  async acknowledgeNotes(timesheetId: any) {
    const result = await msalApiFetch(`${baseUrl}/${endpoint}/${timesheetId}/acknowledge-notes`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    });
    if (!result.ok) throw result;
    return result;
  },
  /**
   * Mark manager signature request as completed
   * @param {string} timesheetId
   */
  async completeManagerRequest(timesheetId: string) {
    const result = await msalApiFetch(
      `${baseUrl}/${endpoint}/${timesheetId}/completeManagerRequest`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }
    );
    if (!result.ok) throw result;
    return result;
  },
  /**
   * Notify manager to sign
   * @param {string} timesheetId
   */
  async notifyManager(timesheetId: string) {
    const result = await msalApiFetch(`${baseUrl}/${endpoint}/${timesheetId}/notifyManager`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    if (!result.ok) throw result;
    return result;
  },
  reasignManagerSignatureRequest(timesheetId: string, manager: Manager | undefined) {
    const url = `${baseUrl}/${endpoint}/${timesheetId}/reassignManagerSignature`;
    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(manager),
    });
  },
  /**
   * Get the recruiter NetSuite lock status
   * @param {String} timesheetId
   * @returns {Promise<Boolean>}
   */
  async getHasTimeEntryInDigitalTimecard(timesheetId: string) {
    const result = await msalApiFetch(`${baseUrl}/${endpoint}/${timesheetId}/has-time-entry`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (!result.ok) throw result;
    return result.json();
  },
  async getExpensesByTimesheetId(timesheetId: string): Promise<HistoryExpense[]> {
    const url = `${baseUrl}/timecards/${timesheetId}/expenses`;
    const result = await msalApiFetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (!result.ok) throw result;
    return result.json();
  },
  async downloadDealsheetAndTimecardDifferences() {
    const url = `${baseUrl}/timecards/dealsheet-and-timecard-differences-report`;
    const response = await msalApiFetch(url, {
      method: "GET",
    });
    return response.blob();
  },
  async acknowledgeRatesChanged(timesheetId: string, note: string) {
    const url = `${baseUrl}/${endpoint}/${timesheetId}/acknowledge-rates-changed`;

    return msalApiFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ contents: note }),
    });
  },
  async hasExpenses(timesheetId: string): Promise<boolean> {
    const url = `${baseUrl}/timesheets/${timesheetId}/has-expenses`;

    const response = await msalApiFetch(url, {
      method: "GET",
    });

    if (!response.ok) throw response;
    return response.json();
  },
};
