import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Agency } from "commons/Enums";

type SwitchAgencyDialogProps = {
  open: boolean;
  onClose: () => void;
};

const SwitchAgencyDialog = (props: SwitchAgencyDialogProps) => {
  const { open, onClose } = props;
  const agency = localStorage.getItem("selectedAgency");
  const [selectedAgency, setSelectedAgency] = useState<string>(agency ?? "");
  const agencies = [
    { id: Agency.Triage, label: "Triage" },
    { id: Agency.TriagePlus, label: "Triage Plus" },
  ];

  const handleSwitch = () => {
    if (selectedAgency) {
      switch (selectedAgency) {
        case Agency.Triage:
          localStorage.setItem("selectedAgency", Agency.Triage);
          break;
        case Agency.TriagePlus:
          localStorage.setItem("selectedAgency", Agency.TriagePlus);
          break;

        default:
          break;
      }

      // force reload the page
      window.location.href = "/";

      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setSelectedAgency(agency ?? "");
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Switch Agency</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <Select value={selectedAgency} onChange={(e) => setSelectedAgency(e.target.value)}>
            {agencies.map((agency) => (
              <MenuItem key={agency.id} value={agency.id}>
                {agency.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSwitch}>Switch</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SwitchAgencyDialog;
