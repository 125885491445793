import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect, useRef, ChangeEvent } from "react";

import { fetchMessages, markMessagesAsRead, replyUserMessage } from "api/MessagingApi";
import moment from "moment";
import { MessageSenderRole } from "commons/Enums";
import { Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { usePermissions } from "hooks";
import { Message } from "types/message/Message";
import { TextAlign } from "types/textAlign";
import { AlignItems } from "types/alignItems";

type RoleStyle = {
  backgroundColor: string;
  textAlignTime: TextAlign;
  textAlignMessage: TextAlign;
  alignItems: AlignItems;
};

const roleStyles: Record<MessageSenderRole, RoleStyle> = {
  [MessageSenderRole.Administrator]: {
    backgroundColor: "#fafafa",
    textAlignTime: "right",
    textAlignMessage: "right",
    alignItems: "flex-end",
  },
  [MessageSenderRole.Application]: {
    backgroundColor: "#fafafa",
    textAlignTime: "right",
    textAlignMessage: undefined,
    alignItems: "flex-end",
  },
  [MessageSenderRole.Traveler]: {
    backgroundColor: "#51468926",
    textAlignTime: "left",
    textAlignMessage: "left",
    alignItems: "flex-start",
  },
};

type UserNotificationsContentProps = {
  isModalOpen: boolean;
  timesheetId: string;
  hasUnreadMessages: boolean;
  onMessagesRead: () => void;
  isReadOnly?: boolean;
};

const UserNotificationsContent = ({
  isModalOpen,
  timesheetId,
  hasUnreadMessages,
  onMessagesRead,
  isReadOnly = false,
}: UserNotificationsContentProps) => {
  const [loading, setLoading] = useState(false);
  const [groupedMessages, setGroupedMessages] = useState<Message[][]>([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [readyToScroll, setReadyToScroll] = useState<boolean>(false);
  const [replyText, setReplyText] = useState("");
  const [replyLoading, setReplyLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { canReplyTextMessages } = usePermissions();

  const groupMessages = (messages: Message[]) => {
    const group = [];
    let currentGroup: Message[] = [];

    messages.forEach((message, index) => {
      if (index === 0 || message.senderRole === messages[index - 1].senderRole) {
        currentGroup.push(message);
      } else {
        group.push(currentGroup);
        currentGroup = [message];
      }
    });

    if (currentGroup.length > 0) {
      group.push(currentGroup);
    }

    setGroupedMessages(group);
  };

  const handleReplyChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReplyText(e.target.value);
  };

  const handleReply = async () => {
    setReplyLoading(true);
    setReadyToScroll(false);

    const myReply: Message = {
      id: moment.utc().toString(),
      content: replyText,
      senderRole: MessageSenderRole.Administrator,
      sentDate: moment.utc().toString(),
    };

    const myMessagesWithReply = groupedMessages.concat([[myReply]]);

    setGroupedMessages(myMessagesWithReply);

    try {
      await replyUserMessage({ timesheetId, message: replyText });
      setReplyText("");
    } catch (er) {
      enqueueSnackbar("Something went wrong. Please try again later.");
      const rollBack = myMessagesWithReply.slice(0, -1);
      setGroupedMessages(rollBack);
    } finally {
      setReplyLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen && timesheetId) {
      setLoading(true);
      fetchMessages(timesheetId)
        .then((res) => {
          groupMessages(res);

          if (hasUnreadMessages) {
            markMessagesAsRead(res.map((message) => message.id))
              .then(() => onMessagesRead())
              .catch(() => console.error("Error marking messages as read"));
          }
        })
        .catch(() => console.error("Error fetching messages"))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (readyToScroll) {
      scrollRef.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [readyToScroll]);

  const emptyMessages = groupedMessages.length === 0;

  if (loading)
    return (
      <Box height={"60vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      height={"60vh"}
      style={{ overflowY: "auto", marginBottom: canReplyTextMessages ? "10vh" : undefined }}>
      {emptyMessages && (
        <Box
          style={{
            backgroundColor: "#51468926",
            padding: 15,
            borderRadius: 15,
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}>
          <Typography style={{ fontWeight: "bold" }}>
            There are no messages in the last 30 days.
          </Typography>
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        <Box>
          {groupedMessages.map((group, index) => {
            if (groupedMessages.length === index + 1 && !readyToScroll) setReadyToScroll(true);

            return (
              <div key={index + JSON.stringify(group)}>
                {group.map((message, messageIndex) => (
                  <Box
                    key={group[0].content + messageIndex}
                    style={{
                      padding: 5,
                      display: "flex",
                      alignItems: roleStyles[message.senderRole].alignItems,
                      flexDirection: "column",
                      margin: 5,
                    }}>
                    <Box
                      style={{
                        maxWidth: "90%",
                        backgroundColor: roleStyles[message.senderRole].backgroundColor,
                        padding: "10px 15px 10px 15px",
                        borderRadius: 8,
                      }}>
                      <Typography
                        style={{ textAlign: roleStyles[message.senderRole].textAlignMessage }}
                        dangerouslySetInnerHTML={{ __html: message.content }}
                      />
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 11,
                          textAlign: roleStyles[message.senderRole].textAlignTime,
                          color: "#838383",
                        }}>
                        {moment(message.sentDate).format("MM/DD/YYYY HH:mm")}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </div>
            );
          })}
        </Box>
        {!emptyMessages && canReplyTextMessages && !isReadOnly && (
          <Box style={{ position: "absolute", width: "95%", bottom: 20 }}>
            <form
              style={{ display: "flex" }}
              onSubmit={(e) => {
                e.preventDefault();
                handleReply();
              }}>
              <TextField
                id="reply-textfield"
                label="Enter your reply"
                variant="outlined"
                fullWidth
                disabled={replyLoading}
                value={replyText}
                onChange={handleReplyChange}
              />
              <Button
                onClick={handleReply}
                color="primary"
                variant="contained"
                disabled={replyLoading || !replyText || replyText.length < 2}
                style={{ margin: "7px 0 7px 20px" }}>
                Reply
              </Button>
            </form>
          </Box>
        )}
      </Box>
      <div ref={scrollRef}></div>
    </Box>
  );
};

export default UserNotificationsContent;
