import { Badge, Chip } from "@mui/material";
import React from "react";
import { UserType } from "types/users";

type ProfileTableHeaderProps = {
  title: string;
  lateTimecardsReleased: boolean;
  profileTableRef?: any;
};

const ProfileTableCellHead = ({
  title,
  profileTableRef,
  lateTimecardsReleased,
}: ProfileTableHeaderProps) => {
  // don't want SalesReps because their travelers are included in the Recruiters
  const filteredRows = profileTableRef.current?.state.data?.filter(
    (row: any) => row.userType === UserType.Recruiter || row.userType === UserType.RecruiterTeamLead
  );

  let countByState = 0;

  switch (title) {
    case "Pending":
      countByState =
        filteredRows?.reduce((acc: number, row: any) => {
          const count = lateTimecardsReleased ? row.pendingCount.total : row.pendingCount.notLate;
          return acc + count;
        }, 0) ?? 0;
      break;
    case "Payroll":
      countByState =
        filteredRows?.reduce((acc: number, row: any) => {
          const count = lateTimecardsReleased
            ? row.forReviewCount.total
            : row.forReviewCount.notLate;
          return acc + count;
        }, 0) ?? 0;
      break;
    case "Billing":
      countByState =
        filteredRows?.reduce((acc: number, row: any) => {
          const count = lateTimecardsReleased ? row.billingCount.total : row.billingCount.notLate;
          return acc + count;
        }, 0) ?? 0;
      break;
    case "Approved":
      countByState =
        filteredRows?.reduce((acc: number, row: any) => {
          const count = lateTimecardsReleased ? row.approvedCount.total : row.approvedCount.notLate;
          return acc + count;
        }, 0) ?? 0;
      break;
    case "Late":
      countByState =
        filteredRows?.reduce((acc: number, row: any) => {
          return acc + row.lateCount;
        }, 0) ?? 0;
      break;
  }

  return (
    <>
      <span>{title}</span>
      <Chip label={countByState} color="primary" size="small" sx={{ ml: 1 }} variant="outlined" />
    </>
  );
};

export default ProfileTableCellHead;
